import React, { FC } from 'react';
import { connect } from 'react-redux';

import SpArtList from '../../containers/SpArtList/SpArtList';
import PriceFilter from '../../containers/PriceFilter/PriceFilter';
import { IRootState } from '../../interfaces';
import SpDetale from './SpDetale/SpDetale';
import DetailFilter from '../../containers/DetailFilters/DetailFilter';

interface Props {
  isSpDetailLoading?: boolean;
  isSpConditionsLoad?: boolean;
  minPrice?: number;
  maxPrice?: number;
}

const AppSpDetale: FC<Props> = ({
  isSpDetailLoading,
  isSpConditionsLoad,
  minPrice,
  maxPrice,
}) => {
  return (
    <div className="row AppSpDetale b-sp__detail">
      <div className="col-lg-24 col-md-24 col-sm-24 col-xs-24">
        <SpDetale />
      </div>
      <div className="col-lg-24 col-md-24 col-sm-24 col-xs-24">
        {!isSpDetailLoading && !isSpConditionsLoad && (
          <PriceFilter setapMin={minPrice} setapMax={maxPrice} isSaveFiltersToUrl />
        )}
      </div>
      <div className="col-lg-24 col-md-24 col-sm-24 col-xs-24">
        <DetailFilter isSaveFiltersToUrl />
      </div>
      <div className="col-lg-24 col-md-24 col-sm-24 col-xs-24">
        <SpArtList typeCard="spDetail" />
      </div>
    </div>
  );
};

function mapStateToProps(state: IRootState) {
  return {
    isSpDetailLoading: state.moduleSpDetail?.spDetailLoad,
    isSpConditionsLoad: state.moduleSpDetail?.spConditionsLoad,
    minPrice: state.moduleSpDetail?.spDetail.filters.minPrice,
    maxPrice: state.moduleSpDetail?.spDetail.filters.maxPrice,
  };
}

export default connect(mapStateToProps)(AppSpDetale);

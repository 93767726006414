import React, { memo } from "react";
import { connect } from "react-redux";
import { setUrl, updateSearch } from "../../../common/getURLParam";
import Select from "../../../components/Select";
import { IRootState } from "../../../interfaces";
import { ASelectSection } from "../state/reducer/actions";

function SectionInput(props: any) {
  const { selectSection, sections, selectSectionFn, sectionLoad } = props;
  const defaultValue = "1. ВЫБЕРИТЕ РАЗДЕЛ";

  const handleClick = (sectionId: number) => {
    selectSectionFn(sectionId);

    const searchStr = updateSearch(
      "section",
      sectionId > 0 ? `${sectionId}` : "Не выбрано"
    );

    setUrl(searchStr);
  };

  return (
    <div className={`SectionInput ${sections.length > 1 ? "" : "SectionHide"}`}>
      <Select
        optionsList={sections}
        selectId={selectSection}
        handleClick={handleClick}
        defaultLabel={defaultValue}
        className="Select"
        defaultClickable
        disabled={sectionLoad}
      />
    </div>
  );
}

function mapStateToProps(state: IRootState) {
  return {
    selectSection: state.categoryFilter?.selectSection,
    sections: state.categoryFilter?.sections,
    sectionLoad: state.categoryFilter?.sectionLoad,
  };
}

export default memo(
  connect(mapStateToProps, {
    selectSectionFn: ASelectSection,
  })(SectionInput)
);

import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ATextSearchOnChange } from '../state/reducer/actions';
import { IAction } from '../../../interfaces';
import getURLParams, {
  setUrl,
  updateSearch,
  urldecode,
} from '../../../common/getURLParam';

interface Props {
  textSearchOnChangeFn: (text: string) => IAction;
}

const TextSearchInput: FC<Props> = ({ textSearchOnChangeFn }) => {
  const [textTimerId, setTextTimerId] = useState<any>();
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(textTimerId);

    const val = e.currentTarget.value;

    const timerId = setTimeout(() => {
      const searchStr = updateSearch('fullText', val);
      setUrl(searchStr);
      textSearchOnChangeFn(val);
    }, 2000);

    setTextTimerId(timerId);
  };

  useEffect(() => {
    const fullText = urldecode(getURLParams('fullText')).trim();

    textSearchOnChangeFn(fullText);

    return () => {
      if (textTimerId) {
        clearTimeout(textTimerId);
      }
    };
  }, []);

  return (
    <div className="TextSearchInput">
      <input
        type="text"
        placeholder="Поиск по словам"
        onChange={handleOnChange}
      />
    </div>
  );
};

export default connect(null, {
  textSearchOnChangeFn: ATextSearchOnChange,
})(TextSearchInput);

import { IAction, IAppSpArtList } from '../../../../interfaces/index';

import {
  SP_ART_LIST_FETCH_FAILED,
  SP_ART_LIST_FETCH_SUCCEEDED,
  SP_ART_LIST_RESET,
  SP_ART_LIST_LOADING_TRUE,
  SET_START_ID_SP_ART,
  SET_START_NUM_SQL_LIST_SP_ART,
  SET_COUNT_IN_SQL_LIST_SP_ART,
  SET_IS_END_SP_TRUE_SP_ART,
  SET_CANCEL_TOKEN_SOURCE,
  SET_CANCEL_TOKEN_SOURCE_RESET,
  SET_LAST_PATH,
} from './actionTypes';

const initialState: IAppSpArtList = {
  spArtList: [],
  spArtListLoad: true,
  startIdSp: 0,
  startNumSqlList: 0,
  countInSqlList: 100,
  lastPath: '',
  isEndSp: false,
  error: '',
};

export default function (state = initialState, action: IAction) {
  switch (action.type) {
    case SP_ART_LIST_RESET:
      return { ...state, spArtList: [], isEndSp: false };
    case SP_ART_LIST_FETCH_SUCCEEDED:
      const newSpList = [...state.spArtList, ...action.payload];
      return { ...state, spArtList: newSpList, spArtListLoad: false };
    case SP_ART_LIST_LOADING_TRUE:
      return { ...state, spArtListLoad: true };
    case SET_IS_END_SP_TRUE_SP_ART:
      return { ...state, isEndSp: true, spArtListLoad: false };
    case SET_START_ID_SP_ART:
      return { ...state, startIdSp: action.payload };
    case SET_LAST_PATH:
      return { ...state, lastPath: action.payload };
    case SET_START_NUM_SQL_LIST_SP_ART:
      return { ...state, startNumSqlList: action.payload };
    case SET_COUNT_IN_SQL_LIST_SP_ART:
      return { ...state, countInSqlList: action.payload };
    case SP_ART_LIST_FETCH_FAILED:
      return {
        ...state,
        error: action.payload,
        // sectionLoad: false,
        catLoad: false,
        detCatLoad: false,
        spArtListLoad: false,
      };
    default:
      return state;
  }
}

import React, { SelectHTMLAttributes } from 'react';

import { ISelectData, ISelectOptions } from '../../interfaces';

function Select(props: ISelectData & SelectHTMLAttributes<HTMLSelectElement>) {
  const {
    optionsList,
    selectId,
    handleClick,
    defaultLabel = '',
    defaultClickable = false,
    ...otherProps
  } = props;

  const onChange = (e: any) => {
    handleClick(e.target.value as number);
    e.preventDefault();
  };

  const options = optionsList.map((opt: ISelectOptions) => (
    <option key={opt.id} value={opt.id}>
      {opt.name}
    </option>
  ));

  return (
    <select value={selectId} onChange={onChange} {...otherProps}>
      <option value="0" disabled={!defaultClickable}>
        {defaultLabel}
      </option>
      {options}
    </select>
  );
}

export default Select;

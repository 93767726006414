import React from 'react';
import { Range, createSliderWithTooltip, Handle } from 'rc-slider';
import Tooltip from 'rc-tooltip';

import 'rc-slider/assets/index.css';
import './style.scss';
import { IPriceRange } from '../../state/interfaces';

interface Props {
  min: number;
  max: number;
  step: number;
  value?: [number, number];
  more?: boolean;
  onChange: (price: IPriceRange) => void;
}

const PriceSlider = ({
  min,
  max,
  value,
  step,
  more = false,
  onChange,
}: Props) => {
  const handle = (props: any) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };
  const RangeWTT = createSliderWithTooltip(Range);
  const trackStyle = [{ background: '#d92819', height: '10px' }];
  const railStyle = { height: '10px', borderRadius: '0px' };
  const dotStyle = {
    borderRadius: 'initial',
    width: '1px',
    height: '14px',
    marginLeft: '-0.5px',
    bottom: '-8px',
    border: '0px',
    backgroundColor: '#7e2119',
  };
  const oneHendleStile = {
    background: 'white',
    border: '2px solid #d92819',
    width: '12px',
    height: '12px',
    top: '8.6px',
  };
  const handleStyle = [oneHendleStile, oneHendleStile];
  const average = Math.round(max / 2);
  const marks = {
    [min]: {
      style: {},
      label: `${min}`,
    },
    [average]: {
      style: {},
      label: average
        .toString()
        .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 '),
    },
    [max]: {
      style: {
        width: '100px',
      },
      label:
        max.toString().replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ') +
        ` ` +
        (more ? ' и более' : ''),
    },
  };
  const handleCange = (arrVal: [number, number]) => {
    const [minVal, maxVal] = arrVal;
    onChange({ min: minVal, max: maxVal });
  };

  return (
    <RangeWTT
      min={min}
      max={max}
      pushable={0}
      trackStyle={trackStyle}
      railStyle={railStyle}
      dotStyle={dotStyle}
      handleStyle={handleStyle}
      onAfterChange={handleCange}
      marks={marks}
      defaultValue={value}
      step={step}
      handle={handle}
      tipProps={{ visible: true }}
      tipFormatter={(value: any) =>
        `${value.toString().replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')}${
          value === max && more ? '+' : ''
        } Руб.`
      }
    />
  );
};

export default PriceSlider;

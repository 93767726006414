import { put, takeEvery, call, select, take } from 'redux-saga/effects';

import {
  SP_ART_LIST_FETCH_REQUESTED,
  SP_ART_LIST_FETCH_FAILED,
} from '../reducer/actionTypes';
import {
  ASpArtListLoadingTrue,
  ASpArtListFetchSucceeded,
  ASetStartNumSQLListSpArt,
  ASetStartIdSpArt,
  ASetIsEndSpArtTrue,
  ASetLastPath,
} from '../reducer/actions';
import { IFetchSpArtListData } from '../interfaces';
// import { getSpArtList } from './API';
import { IRootState, IAppSpArtList, ISpArtList } from '../../../../interfaces';
import { IAppCategoryFilter } from './../../../../containers/CategoryFilter/state/interfaces/index';
import { IAppPriceFilter } from '../../../../containers/PriceFilter/state/interfaces';
import axios from 'axios';
// import { channel, eventChannel } from 'redux-saga';
import isFackeData from '../../../../getDateArea';
import { timeout } from '../../../../common/getURLParam';

let controller = new AbortController();
let timerId = 0;

export function* spListFetchRequest() {
  yield takeEvery(SP_ART_LIST_FETCH_REQUESTED, spListFetch);
}

function* getDataFromStateForSpListFetch() {
  const state: IRootState = yield select();
  const { categoryFilter, moduleSpArtListFilter, priceFilter } = state;
  const { selectSection, selectCat, selectDetCat, textSearch } =
    categoryFilter as IAppCategoryFilter;
  const { startIdSp, startNumSqlList, countInSqlList, lastPath, spArtList } =
    moduleSpArtListFilter as IAppSpArtList;
  const { priceMax, priceMin, valueMax, valueMin, moreThanMax } =
    priceFilter as IAppPriceFilter;

  return {
    startIdSp,
    startNumSqlList,
    countInSqlList,
    lastPath,
    section: selectSection,
    category: selectCat,
    categoryDetal: selectDetCat,
    typePromo: null,
    valueMin: priceMin !== valueMin ? valueMin : -1,
    moreThanMax: moreThanMax,
    ramgeMin: priceMin,
    valueMax: priceMax !== valueMax ? valueMax : -1,
    ramgeMax: priceMax,
    text: textSearch,
  } as IFetchSpArtListData;
}

function* spListFetch() {
  timerId = ++timerId;
  const currentTimer = timerId;

  yield put(ASpArtListLoadingTrue());
  yield timeout();

  const isGetRequest = currentTimer === timerId;

  if (isGetRequest) {
    const dataSend: IFetchSpArtListData =
      yield getDataFromStateForSpListFetch();

    const { countInSqlList: count, startNumSqlList: startNum } = dataSend;

    try {
      const splist: ISpArtList = yield call(getSpArtList, dataSend);

      if (splist) {
        // yield setStartIdSp(splist, startNum);
        yield setIsEndSpTrue(splist.length, count);
        yield setStartNumSQLList(startNum, count);
        yield put(ASpArtListFetchSucceeded(splist));
      }
    } catch (e) {
      console.error(e);
      //@ts-ignore
      if (e.message !== 'canceled') {
        //@ts-ignore
        yield put({ type: SP_ART_LIST_FETCH_FAILED, payload: e.message });
      }
    }
  }
}

function* setStartIdSp(splist: ISpArtList, startNum: number) {
  if (startNum === 0 && splist.length > 0) {
    yield put(ASetStartIdSpArt(splist[0].topic_id));
  }
}

function* setIsEndSpTrue(length: number, count: number) {
  if (length < count) {
    yield put(ASetIsEndSpArtTrue());
  }
}

function* setStartNumSQLList(startNum: number, count: number) {
  const newStartNumSQLList = startNum + count;
  yield put(ASetStartNumSQLListSpArt(newStartNumSQLList));
}

function* getSpArtList(dataSend: IFetchSpArtListData) {
  const {
    startNumSqlList,
    countInSqlList,
    startIdSp,
    section,
    category,
    categoryDetal,
    typePromo,
    valueMin,
    valueMax,
    ramgeMin,
    ramgeMax,
    moreThanMax,
    text,
    lastPath,
  } = dataSend;
  let sentCatId = '';

  if (categoryDetal) {
    sentCatId = `&cd=${categoryDetal}`;
  } else if (category) {
    sentCatId = `&c=${category}`;
  } else if (section) {
    sentCatId = `&sc=${section}`;
  }

  let sentTypePromo = '';
  if (typePromo) {
    sentTypePromo = `&typePromo=${typePromo}`;
  }

  let sentMinMaxPrice = '';
  if (valueMax !== -1 || valueMin !== -1) {
    let min = valueMin;
    let max = valueMax;
    if (valueMin === -1) {
      //если не определено, то равно дефолтному значению
      min = ramgeMin;
    }
    if (valueMax === -1) {
      //если не определено, то равно дефолтному значению
      max = ramgeMax;

      if (moreThanMax) {
        max = 999999;
      }
    }
    sentMinMaxPrice = `&minp=${min}&maxp=${max}`;
  }

  let strUrl = '';

  if (isFackeData) {
    strUrl = `/APIFilterArts/spArtlist.json?type=filter${sentCatId}&idSp=${startIdSp}${sentTypePromo}&s=${startNumSqlList}&n=${countInSqlList}${sentMinMaxPrice}&txt=${text}`;
  } else {
    strUrl = `/APIFilterArts/?type=filter${sentCatId}&idSp=${startIdSp}${sentTypePromo}&s=${startNumSqlList}&n=${countInSqlList}${sentMinMaxPrice}&txt=${text}`;
  }

  if (
    categoryDetal === 0 &&
    category === 0 &&
    section === 0 &&
    valueMin === -1 &&
    valueMax === -1 &&
    text === '' &&
    !typePromo
  ) {
    if (isFackeData) {
      strUrl = `/APIFilterArts/spArtlist.json?s=${startNumSqlList}&n=${countInSqlList}&idSp=${startIdSp}`;
    } else {
      strUrl = `/APIFilterArts/?s=${startNumSqlList}&n=${countInSqlList}&idSp=${startIdSp}`;
    }
  }

  controller.abort();
  controller = new AbortController();

  // if (lastPath !== strUrl) {
  // console.log(323)
  // yield put(ASetLastPath(strUrl));

  //@ts-ignore
  const res = yield axios.get(strUrl, {
    signal: controller.signal,
  });

  const { data } = res;
  yield put(ASetLastPath(''));
  return data;
  // } else {
  //   return null
  // }
}

export default function (key: string): string {
  const p = window.location.search;
  const retp = p.match(new RegExp(key + '=([^&=]+)'));
  return retp ? retp[1] : '';
}

export function timeout(ms: number = 500) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function urldecode(str: string): string {
  return decodeURIComponent((str + '').replace(/\+/g, '%20'));
}

export function setUrl(search: string) {
  window.history.pushState('', '', search);
}

export const updateSearchURL = (name: string, value: string) => {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.set(name, value);

  var newUrl =
    window.location.origin +
    window.location.pathname +
    (Boolean(searchParams.toString()) ? '?' : '') +
    searchParams.toString();
  window.history.pushState({ path: newUrl }, '', newUrl);
};

export const getSearch = (name: string) => {
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get(name);
};

export const deleteSearch = (name: string) => {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.delete(name);

  var newUrl =
    window.location.origin +
    window.location.pathname +
    (Boolean(searchParams.toString()) ? '?' : '') +
    searchParams.toString();
  window.history.pushState({ path: newUrl }, '', newUrl);
};

export function updateSearch(
  name: 'section' | 'category' | 'dcategory' | 'fullText' | 'price',
  value: string
): string {
  const noValue = 'Не выбрано';
  const p = window.location.search;
  const sectionParameter = p.match(new RegExp('section=([^&=]+)'));
  const section = sectionParameter ? sectionParameter[1] : '';

  const categoryParameter = p.match(new RegExp('category=([^&=]+)'));
  const category = categoryParameter ? categoryParameter[1] : '';

  const dcategoryParameter = p.match(new RegExp('dcategory=([^&=]+)'));
  const dcategory = dcategoryParameter ? dcategoryParameter[1] : '';

  const fullTextParameter = p.match(new RegExp('fullText=([^&=]+)'));
  const fullText = fullTextParameter ? fullTextParameter[1] : '';

  const minPriceParameter = p.match(new RegExp('minPrice=([^&=]+)'));
  const minPrice = minPriceParameter ? minPriceParameter[1] : '';

  const maxPriceParameter = p.match(new RegExp('maxPrice=([^&=]+)'));
  const maxPrice = maxPriceParameter ? maxPriceParameter[1] : '';
  console.log(value);
  let returnSearch = '';
  let searchNewQuery = '';
  let searchTail = '';

  switch (name) {
    case 'section':
    case 'category':
    case 'dcategory':
      searchTail = p.replace(`&section=${section}`, '');
      searchTail = searchTail.replace(`section=${section}`, '');
      searchTail = searchTail.replace(`&category=${category}`, '');
      searchTail = searchTail.replace(`&dcategory=${dcategory}`, '');

      break;
    case 'fullText':
      searchTail = p.replace(`&fullText=${fullText}`, '');
      searchTail = searchTail.replace(`fullText=${fullText}`, '');

      break;
    case 'price':
      searchTail = p.replace(`&minPrice=${minPrice}`, '');
      searchTail = searchTail.replace(`minPrice=${minPrice}`, '');
      searchTail = searchTail.replace(`&maxPrice=${maxPrice}`, '');
      searchTail = searchTail.replace(`maxPrice=${maxPrice}`, '');

      break;
  }

  switch (name) {
    case 'section':
      if (value === noValue) {
        searchNewQuery = ``;
      } else {
        searchNewQuery = `section=${value}`;
      }

      break;
    case 'category':
      if (value === noValue) {
        searchNewQuery = `section=${section}`;
      } else {
        searchNewQuery = `section=${section}&category=${value}`;
      }

      break;
    case 'dcategory':
      if (value === noValue) {
        searchNewQuery = `section=${section}&category=${category}`;
      } else {
        searchNewQuery = `section=${section}&category=${category}&dcategory=${value}`;
      }

      break;
    case 'fullText':
      if (value === '') {
        searchNewQuery = '';
      } else {
        searchNewQuery = `fullText=${value}`;
      }

      break;
    case 'price':
      const [minValue, maxValue] = value.split('=');

      if (minValue === '0') {
        searchNewQuery = '';
      } else {
        searchNewQuery = `minPrice=${minValue}`;
      }

      if (maxValue === '0') {
        searchNewQuery += '';
      } else {
        // searchNewQuery = `${searchNewQuery === '' ? '' : '&'}maxPrice=${maxValue}`;
        searchNewQuery = `maxPrice=${maxValue}`;
      }

      break;
  }

  if (searchTail.trim().length > 5) {
    if (searchNewQuery !== '') {
      returnSearch = `${searchTail}&${searchNewQuery}`;
    } else {
      returnSearch = `${searchTail}`;
    }
  } else {
    returnSearch = `?${searchNewQuery}`;
  }

  console.log(searchTail, searchNewQuery, returnSearch);

  return returnSearch;
}

export interface ISpList extends Array<ISPCard> {
  [index: number]: ISPCard;
}

export interface ISPCard {
  number_of_orders: string | null;
  topic_id: number;
  size: string;
  title: string;
  number: string;
  ico_label: string | null;
  class_label: string | null;
  forum_name: string | null;
  name_org: string;
  link_org_prof: string;
  users_in_rating: string | number;
  rating: string | number;
  participants: string | number;
  link_detal_sp: string;
  title_img: string;
  alt_img: string;
  is_end: boolean;
  src_img: string;
}

export interface IFetchSpListData {
  startIdSp: number;
  startNumSqlList: number;
  countInSqlList: number;
  section: number;
  category: number;
  categoryDetal: number;
  typeSp: MainSortSP;
}

export interface IAppSpList {
  spList: ISpList;
  spListLoad: boolean;
  startIdSp: number;
  startNumSqlList: number;
  countInSqlList: number;
  isEndSp: boolean;
  error: string;
  isGreedView: ViewListSP;
  typeSp: MainSortSP;
}

export enum ViewListSP {
  Greed = "Greed",
  Line = "Line"
}

export enum MainSortSP {
  NewSP = "NewSP",
  Sales = "Sales",
  // StopSP = "StopSP",
  // TopSP = "TopSP",
  // TopArts = "TopArts",
}

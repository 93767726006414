import {
  SIZE_SELECT,
  SIZE_DESELECT,
  SEX_SELECT,
  TEXT_INPUT,
  SEX_DESELECT,
  SIZE_FILTER_UPDATE,
  SEX_FILTER_UPDATE,
  TEXT_INPUT_UPDATE,
} from './actionTypes';

export function ASetSizeSelect(val: string[]) {
  return { type: SIZE_SELECT, payload: val };
}

export function ASetSizeDeselect(val: string) {
  return { type: SIZE_DESELECT, payload: val };
}

export function ASetSexSelect(val: string[]) {
  return { type: SEX_SELECT, payload: val };
}

export function ASetTextSelect(val: string) {
  return { type: TEXT_INPUT, payload: val };
}

export function ASetSexDeselect(val: string) {
  return { type: SEX_DESELECT, payload: val };
}

export function ASetUpdateFilterSize() {
  return { type: SIZE_FILTER_UPDATE };
}

export function ASetUpdateFilterSex() {
  return { type: SEX_FILTER_UPDATE };
}

export function ASetUpdateFilterText() {
  return { type: TEXT_INPUT_UPDATE };
}

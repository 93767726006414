import React from 'react';
import { Provider } from 'react-redux';

import store from '../state/store';
import AppCategoryFilter from '../modules/AppCategoryFilter';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

function AppSpWithFilter() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <AppCategoryFilter />
      </Provider>
    </ErrorBoundary>
  );
}

export default AppSpWithFilter;

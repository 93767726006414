import React, { memo, ReactElement } from 'react';

import CategoryFilter from '../../containers/CategoryFilter';
import SpArtList from '../../containers/SpArtList/SpArtList';
import PriceFilter from './../../containers/PriceFilter/PriceFilter';

function AppSpArtFilter(): ReactElement {
  return (
    <div className="row AppSpArtFilter">
      <div className="col-lg-24 col-md-24 col-sm-24 col-xs-24">
        <CategoryFilter isTextFilter />
      </div>
      <div className="col-lg-24 col-md-24 col-sm-24 col-xs-24">
        <PriceFilter setapMin={0} setapMax={2000} more />
      </div>
      <div className="col-lg-24 col-md-24 col-sm-24 col-xs-24">
        <SpArtList />
      </div>
    </div>
  );
}

export default memo(AppSpArtFilter);

import { ICategoryArr } from '../interfaces';
import {
  ALL_RESET,
  SECTION_FETCH_REQUESTED,
  SECTION_SELECT,
  SECTION_FETCH_SUCCEEDED,
  CATEGORY_FETCH_REQUESTED,
  DETAIL_CATEGORY_FETCH_REQUESTED,
  CATEGORY_FETCH_SUCCEEDED,
  CATEGORY_SELECT,
  DETAIL_CATEGORY_FETCH_SUCCEEDED,
  DETAIL_CATEGORY_SELECT,
  SECTION_LOADING_TRUE,
  CATEGORY_LOADING_TRUE,
  DETAIL_CATEGORY_LOADING_TRUE,
  CATEGORY_FILTER_UPDATE,
  TEXT_SEARCH_FETCH_REQUESTED,
  TEXT_SEARCH_FETCH_SUCCEEDED,
  TEXT_SEARCH_ON_CHANGE,
  TEXT_SEARCH_LOADING_TRUE,
  SECTION_ALL_RESET,
  CATEGORY_ALL_RESET,
  DETAIL_CATEGORY_ALL_RESET,
} from './actionTypes';

export function AAllReset() {
  return { type: ALL_RESET };
}

// Section
export function ASectionFetchSucceeded(sections: ICategoryArr) {
  return { type: SECTION_FETCH_SUCCEEDED, payload: sections };
}
export function ASectionFetchRequest() {
  return { type: SECTION_FETCH_REQUESTED };
}
export function ASelectSection(sectionId: number) {
  return { type: SECTION_SELECT, payload: sectionId };
}
export function ASectionLoadingTrue() {
  return { type: SECTION_LOADING_TRUE };
}
export function ASectionAllReset() {
  return { type: SECTION_ALL_RESET };
}

// Category
export function ACategoryFetchSucceeded(categoryes: ICategoryArr) {
  return { type: CATEGORY_FETCH_SUCCEEDED, payload: categoryes };
}
export function ACategoryFetchRequest(parentCategoryId: number) {
  return { type: CATEGORY_FETCH_REQUESTED, payload: parentCategoryId };
}
export function ASelectCategory(categoryId: number) {
  return { type: CATEGORY_SELECT, payload: categoryId };
}
export function ACategoryLoadingTrue() {
  return { type: CATEGORY_LOADING_TRUE };
}
export function ACategoryAllReset() {
  return { type: CATEGORY_ALL_RESET };
}

// Detail category
export function ADetailCategoryFetchSucceeded(category: ICategoryArr) {
  return { type: DETAIL_CATEGORY_FETCH_SUCCEEDED, payload: category };
}
export function ADetailCategoryFetchRequest(section: number) {
  return { type: DETAIL_CATEGORY_FETCH_REQUESTED, payload: section };
}
export function ASelectDetailCategory(sectionId: number) {
  return { type: DETAIL_CATEGORY_SELECT, payload: sectionId };
}
export function ADetailCategoryLoadingTrue() {
  return { type: DETAIL_CATEGORY_LOADING_TRUE };
}
export function ADetailCategoryAllReset() {
  return { type: DETAIL_CATEGORY_ALL_RESET };
}

// Text search
export function ATextSearchFetchSucceeded(category: ICategoryArr) {
  return { type: TEXT_SEARCH_FETCH_SUCCEEDED, payload: category };
}
export function ATextSearchFetchRequest(text: string) {
  return { type: TEXT_SEARCH_FETCH_REQUESTED, payload: text };
}
export function ATextSearchOnChange(text: string) {
  return { type: TEXT_SEARCH_ON_CHANGE, payload: text };
}
export function ATextSearchLoadingTrue() {
  return { type: TEXT_SEARCH_LOADING_TRUE };
}

// Update Data
export function ACategoruFilterUpdate() {
  return { type: CATEGORY_FILTER_UPDATE };
}

import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { IRootState } from '../../../interfaces';
import { IAppSpDetail, ISpDetailIds } from '../state/interfaces';
import Loader from '../../../components/Loader/Loader';
import { useInitialState } from './useHooks';
import { ASpDetailAddActualIds } from '../state/reducer/actions';
import getURLParams from '../../../common/getURLParam';

const DMap: Record<number, number> = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
  12: 12,
  13: 13,
  14: 14,
  15: 15,
  16: 16,
  17: 17,
  18: 18,
  19: 19,
  20: 20,
  21: 21,
  22: 22,
  23: 23,
  24: 24,
  25: 25,
  26: 26,
  27: 27,
  28: 28,
  29: 29,
  30: 30,
  31: 31,
  32: 32,
  33: 33,
  34: 34,
  35: 35,
  36: 36,
  37: 37,
  38: 38,
  39: 39,
  40: 40,
  41: 41,
  42: 42,
  43: 43,
  44: 44,
  45: 45,
  46: 46,
  47: 47,
  48: 48,
  49: 49,
  50: 50,
  51: 51,
  52: 52,
  53: 53,
  54: 54,
  55: 55,
  56: 56,
  57: 57,
  58: 58,
  59: 59,
  60: 60,
  61: 61,
  62: 62,
  63: 63,
  64: 64,
  65: 65,
  66: 66,
  67: 67,
  68: 68,
  69: 69,
  70: 70,
  71: 71,
  72: 72,
  73: 73,
  74: 74,
  75: 75,
  76: 76,
  77: 77,
  78: 78,
  79: 79,
  80: 80,
  81: 81,
  82: 82,
  83: 83,
  84: 84,
  85: 85,
  86: 86,
  87: 87,
  88: 88,
  89: 89,
  90: 90,
  91: 91,
  92: 92,
  93: 93,
  94: 94,
  95: 95,
  96: 96,
  97: 97,
  98: 98,
  99: 99,
  100: 100,
  101: 101,
  102: 102,
  103: 103,
  104: 104,
  105: 105,
  106: 106,
  107: 107,
  108: 108,
  109: 109,
  110: 110,
  111: 111,
  112: 112,
  113: 113,
  114: 114,
  115: 115,
  116: 116,
  117: 117,
  118: 118,
  119: 119,
  120: 120,
  121: 121,
  122: 122,
  123: 123,
  124: 124,
  125: 125,
  126: 126,
  127: 127,
  1027: 129,
  8225: 135,
  1046: 198,
  8222: 132,
  1047: 199,
  1168: 165,
  1048: 200,
  1113: 154,
  1049: 201,
  1045: 197,
  1050: 202,
  1028: 170,
  160: 160,
  1040: 192,
  1051: 203,
  164: 164,
  166: 166,
  167: 167,
  169: 169,
  171: 171,
  172: 172,
  173: 173,
  174: 174,
  1053: 205,
  176: 176,
  177: 177,
  1114: 156,
  181: 181,
  182: 182,
  183: 183,
  8221: 148,
  187: 187,
  1029: 189,
  1056: 208,
  1057: 209,
  1058: 210,
  8364: 136,
  1112: 188,
  1115: 158,
  1059: 211,
  1060: 212,
  1030: 178,
  1061: 213,
  1062: 214,
  1063: 215,
  1116: 157,
  1064: 216,
  1065: 217,
  1031: 175,
  1066: 218,
  1067: 219,
  1068: 220,
  1069: 221,
  1070: 222,
  1032: 163,
  8226: 149,
  1071: 223,
  1072: 224,
  8482: 153,
  1073: 225,
  8240: 137,
  1118: 162,
  1074: 226,
  1110: 179,
  8230: 133,
  1075: 227,
  1033: 138,
  1076: 228,
  1077: 229,
  8211: 150,
  1078: 230,
  1119: 159,
  1079: 231,
  1042: 194,
  1080: 232,
  1034: 140,
  1025: 168,
  1081: 233,
  1082: 234,
  8212: 151,
  1083: 235,
  1169: 180,
  1084: 236,
  1052: 204,
  1085: 237,
  1035: 142,
  1086: 238,
  1087: 239,
  1088: 240,
  1089: 241,
  1090: 242,
  1036: 141,
  1041: 193,
  1091: 243,
  1092: 244,
  8224: 134,
  1093: 245,
  8470: 185,
  1094: 246,
  1054: 206,
  1095: 247,
  1096: 248,
  8249: 139,
  1097: 249,
  1098: 250,
  1044: 196,
  1099: 251,
  1111: 191,
  1055: 207,
  1100: 252,
  1038: 161,
  8220: 147,
  1101: 253,
  8250: 155,
  1102: 254,
  8216: 145,
  1103: 255,
  1043: 195,
  1105: 184,
  1039: 143,
  1026: 128,
  1106: 144,
  8218: 130,
  1107: 131,
  8217: 146,
  1108: 186,
  1109: 190,
};

const unicodeToWin1251_UrlEncoded = (s: string) => {
  var L = [];
  for (let i = 0; i < s.length; i++) {
    const ord = s.charCodeAt(i);
    if (!(ord in DMap))
      throw 'Character ' + s.charAt(i) + " isn't supported by win1251!";
    L.push('%' + DMap[ord].toString(16));
  }
  return L.join('').toUpperCase();
};

interface Props {
  moduleSpDetail?: IAppSpDetail;
  spDetailAddActualIds?: (ids: ISpDetailIds) => void;
  maxLabelPrice?: number;
  minLabelPrice?: number;
  valMaxPrice?: number;
  valMinPrice?: number;
}

const SpDetale: FC<Props> = ({
  moduleSpDetail,
  spDetailAddActualIds,
  maxLabelPrice,
  minLabelPrice,
  valMaxPrice,
  valMinPrice,
}) => {
  const {
    // error,
    spConditions,
    spConditionsLoad,
    spDetail,
    spDetailLoad,
    spId,
  } = moduleSpDetail!;

  const artId = getURLParams('art') as unknown as number;
  const {
    number_of_orders,
    artImgUrl,
    imgUrl,
    class_label,
    ico_label,
    dateStopSP,
    dateOpenSP,
    titleSP,
    codeSP,
    description,
    icoUrl,
    orgUrl,
    orgName,
    orgStat,
    orgPer,
    orgId,
    participants,
    countTovarSP,
    imgSize,
    dataSize,
  } = spDetail;
  let initSpUrlImg = artImgUrl ? artImgUrl : imgUrl;
  const [spUrlImg, setSpUrlImg] = useState(initSpUrlImg);
  const isArtId = Boolean(artId);
  const url = isArtId
    ? `lot-zakupki-detalno?sp=${spId}&art=${artId}`
    : `sovmestnaya-pokupka-detalno?sp=${spId}`;

  useEffect(() => {
    console.log({ maxLabelPrice, valMaxPrice, minLabelPrice, valMinPrice });
    if (maxLabelPrice !== valMaxPrice || minLabelPrice !== valMinPrice) {
      setSpUrlImg(imgUrl);
    } else {
      setSpUrlImg(artImgUrl);
    }
  }, [
    maxLabelPrice,
    minLabelPrice,
    valMaxPrice,
    valMinPrice,
    imgUrl,
    artImgUrl,
  ]);

  useInitialState(spDetailAddActualIds);

  return (
    <div className="SpDetail">
      {spDetailLoad ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div
              className={`col-lg-${imgSize} col-md-${imgSize} col-sm-${imgSize} col-xs-24`}
            >
              <a
                className="spDetail__pvb-link"
                href={`/index.php/magazin/${url}`}
              >
                <div className="b-sp__detail-image">
                  <img src={spUrlImg} alt="" title="" />
                </div>
                {isArtId ? (
                  <div
                    style={{ display: 'none' }}
                    className="b-sp__detail-more-detailed"
                  >
                    <div>+</div>
                    <span>Заказать</span>
                  </div>
                ) : null}
              </a>
            </div>
            <div
              className={`col-lg-${dataSize} col-md-${dataSize} col-sm-${dataSize} col-xs-24`}
            >
              <div className={`b-sp__about-purchase ${class_label}`}>
                <div className="b-sp__lable-stop">{dateStopSP}</div>
                <div className="b-sp__lable">
                  <div className="b-sp__lable-content positioning-block__yes">
                    <div className="b-sp__lable-date">
                      Закупка открыта {dateOpenSP}
                    </div>
                    <a
                      className="b-sp__lable-title"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://forum.omskmama.ru/viewtopic.php?t=${spId}`}
                    >
                      {titleSP}
                    </a>
                    <div className="b-sp__discuss">
                      <a
                        className="permitted"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://forum.omskmama.ru/viewtopic.php?t=${spId}`}
                      >
                        Обсудить закупку
                      </a>
                    </div>
                    <div className="b-sp__lable-number">
                      Код закупки {codeSP}
                    </div>
                    {Boolean(number_of_orders) ? (
                      <div className="b-sp__lable-number">
                        Кол-во заказов: {number_of_orders}
                      </div>
                    ) : null}
                    <p className="b-sp__lable-description">{description}</p>
                    <span className="b-sp__lable-icon-group">
                      <i
                        className={`icon-size-m icon-position-m ${ico_label}`}
                      ></i>
                    </span>
                  </div>
                </div>
                <div className="b-sp__lable-org">
                  <div className="b-sp__lable-org-content positioning-block__yes">
                    <img
                      className="b-sp__lable-org-icon"
                      title=""
                      alt=""
                      src={icoUrl}
                    />
                    <div className="b-sp__lable-org-info">
                      <a
                        className="open-org-list b-sp__lable-org-name"
                        data-org-id={orgId}
                        href={orgUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {orgName}
                      </a>
                      <div className="b-sp__lable-org-stat">
                        (<a href="/">{orgStat}</a>{' '}
                        <i className="icon icon-lb-star"></i>)
                      </div>
                      <span className="orgPer">{orgPer}%</span>
                      <div className="b-sp__lable-number-participants">
                        {participants}
                      </div>
                      <div className="b-sp__send_org">
                        <a
                          className="permitted"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://forum.omskmama.ru/privmsg.php?mode=post&u=${orgId}&tt=${unicodeToWin1251_UrlEncoded(
                            titleSP
                          )}`}
                        >
                          Написать ОРГу в ЛС
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="b-sp__lable-condition">
                  {spConditionsLoad ? (
                    <Loader />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: spConditions }} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row b-sp-pb__header">
            <div className="col-lg-24 col-md-24 col-sm-24 col-xs-24">
              <div className="b-sp-pb__header-title">
                <span>{countTovarSP}</span> товаров в закупке
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state: IRootState) {
  return {
    moduleSpDetail: state.moduleSpDetail,
    maxLabelPrice: state.priceFilter?.priceMax,
    minLabelPrice: state.priceFilter?.priceMin,
    valMaxPrice: state.priceFilter?.valueMax,
    valMinPrice: state.priceFilter?.valueMin,
  };
}

export default connect(mapStateToProps, {
  spDetailAddActualIds: ASpDetailAddActualIds,
})(SpDetale);

// SpList
export const SP_ART_LIST_FETCH_REQUESTED = 'SP_ART_LIST_FETCH_REQUESTED';
export const SP_ART_LIST_RESET = 'SP_ART_LIST_RESET';
export const SP_ART_LIST_FETCH_FAILED = 'SP_ART_LIST_FETCH_FAILED';
export const SP_ART_LIST_FETCH_SUCCEEDED = 'SP_ART_LIST_FETCH_SUCCEEDED';
export const SP_ART_LIST_LOADING_TRUE = 'SP_ART_LIST_LOADING_TRUE';
export const SET_START_ID_SP_ART = 'SET_START_ID_SP_ART';
export const SET_CANCEL_TOKEN_SOURCE = 'SET_CANCEL_TOKEN_SOURCE';
export const SET_CANCEL_TOKEN_SOURCE_RESET = 'SET_CANCEL_TOKEN_SOURCE_RESET';
export const SET_START_NUM_SQL_LIST_SP_ART = 'SET_START_NUM_SQL_LIST_SP_ART';
export const SET_COUNT_IN_SQL_LIST_SP_ART = 'SET_COUNT_IN_SQL_LIST_SP_ART';
export const SET_IS_END_SP_TRUE_SP_ART = 'SET_IS_END_SP_TRUE_SP_ART';
export const SET_LAST_PATH = 'SET_LAST_PATH';
// SpList end

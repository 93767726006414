import {
  PRICE_SELECT,
  SET_STEP,
  SET_MIN,
  SET_MAX,
  SET_MIN_VAL,
  SET_MAX_VAL,
  PRICE_FILTER_UPDATE,
  SET_MORE_THAN_MAX,
} from './actionTypes';
import { IPriceRange } from '../interfaces';

export function ASelectRangePrice(price: IPriceRange) {
  return { type: PRICE_SELECT, payload: price };
}
export function ASetMinPrice(min: number) {
  return { type: SET_MIN, payload: min };
}
export function ASetMaxPrice(max: number) {
  return { type: SET_MAX, payload: max };
}
export function ASetMinValPrice(min: number) {
  return { type: SET_MIN_VAL, payload: min };
}
export function ASetMaxValPrice(max: number) {
  return { type: SET_MAX_VAL, payload: max };
}
export function ASetStepPrice(step: number) {
  return { type: SET_STEP, payload: step };
}
export function APriceFilterUpdate() {
  return { type: PRICE_FILTER_UPDATE };
}
export function APriceFilterMoreThanMaxUpdate(more: boolean) {
  return { type: SET_MORE_THAN_MAX, payload: more };
}

import { all } from 'redux-saga/effects';

import { sectionFetchRequest } from './section';
import { categoryFetchRequest } from './category';
import { detailCategoryFetchRequest } from './detailCategory';

export function* categoryfilter() {
  yield all([
    sectionFetchRequest(),
    categoryFetchRequest(),
    detailCategoryFetchRequest(),
  ]);
}

import React, { FC, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../interfaces';
import { MainSortSP } from '../../modules/AppCategoryFilter/state/interfaces';
import { ASetTypeSp } from '../../modules/AppCategoryFilter/state/reducer/actions';
import { ACategoruFilterUpdate } from '../CategoryFilter/state/reducer/actions';

const BUTTONS_NAME = {
  [MainSortSP[MainSortSP.NewSP]]: 'Новые СП',
  [MainSortSP[MainSortSP.Sales]]: 'Распродажи',
} as const;

const MainSort: FC = () => {
   const dispatch = useDispatch();
  const typeSp = useSelector((state: IRootState) => state.moduleSpListFilter?.typeSp);

  const handleOnClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    const nameLink = event.currentTarget.getAttribute('data-name');
    const isClickNoActiveButton = typeSp !== nameLink;
    const isNewSpUpdate = isClickNoActiveButton && nameLink === MainSortSP[MainSortSP.NewSP];
    const isSaleSpUpdate = isClickNoActiveButton && nameLink === MainSortSP[MainSortSP.Sales];

    if (isNewSpUpdate) {
      // setView(ViewListSP.Greed);
      dispatch(ASetTypeSp(nameLink as MainSortSP));
      dispatch(ACategoruFilterUpdate())
    } else if (isSaleSpUpdate) {
      // setView(ViewListSP.Line);
      dispatch(ASetTypeSp(nameLink as MainSortSP));
      dispatch(ACategoruFilterUpdate())
    }
  };

  return (
    <ul className="MainSort">
      {(Object.keys(MainSortSP) as Array<keyof typeof MainSortSP>).map(
        (key) => (
          <li
            className={`MainSort__button${
              key === typeSp ? '_active' : ''
            }`}
            key={key}
          >
            <a href="#" onClick={handleOnClick} data-name={key}>
              {BUTTONS_NAME[key]}
            </a>
          </li>
        )
      )}
    </ul>
  );
};

export default MainSort;

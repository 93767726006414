import { ISpList, MainSortSP, ViewListSP } from '../interfaces';
import {
  SET_COUNT_IN_SQL_LIST,
  SET_IS_END_SP_TRUE,
  SET_START_ID_SP,
  SET_START_NUM_SQL_LIST,
  SET_TYPE_SP,
  SET_VIEW,
  SP_LIST_FETCH_REQUESTED,
  SP_LIST_FETCH_SUCCEEDED,
  SP_LIST_LOADING_TRUE,
  SP_LIST_RESET,
} from './actionTypes';

// SpList
export function ASetView(viewListSP: ViewListSP) {
  return { type: SET_VIEW, payload: viewListSP };
}
export function ASpListReset() {
  return { type: SP_LIST_RESET };
}
export function ASpListFetchSucceeded(spList: ISpList) {
  return { type: SP_LIST_FETCH_SUCCEEDED, payload: spList };
}
export function ASpListFetchRequest() {
  return { type: SP_LIST_FETCH_REQUESTED };
}
export function ASpListLoadingTrue() {
  return { type: SP_LIST_LOADING_TRUE };
}
export function ASetIsEndSpTrue() {
  return { type: SET_IS_END_SP_TRUE };
}
export function ASetStartIdSp(id: number) {
  return { type: SET_START_ID_SP, payload: id };
}
export function ASetStartNumSQLList(num: number) {
  return { type: SET_START_NUM_SQL_LIST, payload: num };
}
export function ASetCountInSQLList(num: number) {
  return { type: SET_COUNT_IN_SQL_LIST, payload: num };
}
export function ASetTypeSp(typeSp: MainSortSP) {
  return { type: SET_TYPE_SP, payload: typeSp };
}

import React from 'react';
import { Provider } from 'react-redux';

import store from '../state/store';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import AppSpArtFilter from '../modules/AppSpArtFilter/AppSpArtFilter';

function AppSpArtsFilter() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <AppSpArtFilter />
      </Provider>
    </ErrorBoundary>
  );
}

export default AppSpArtsFilter;

import { put, takeEvery, call } from 'redux-saga/effects';
import {
  DETAIL_CATEGORY_FETCH_REQUESTED,
  DETAIL_CATEGORY_FETCH_FAILED,
} from '../reducer/actionTypes';
import { IAction } from './../../../../interfaces/index';
import { ICategory } from './../interfaces/index';
import { getDetailCategory } from './API';
import {
  ADetailCategoryFetchSucceeded,
  ADetailCategoryLoadingTrue,
} from '../reducer/actions';

export function* detailCategoryFetchRequest() {
  yield takeEvery(DETAIL_CATEGORY_FETCH_REQUESTED, detailCategoryFetch);
}

function* detailCategoryFetch(action: IAction) {
  yield put(ADetailCategoryLoadingTrue());
  try {
    const category: ICategory[] = yield call(
      getDetailCategory,
      action.payload as number
    );
    yield put(ADetailCategoryFetchSucceeded(category));
  } catch (e) {
    console.error(e);

    //@ts-ignore
    if (e.message !== 'canceled') {
      //@ts-ignore
      yield put({ type: DETAIL_CATEGORY_FETCH_FAILED, payload: e.message });
    }
  }
}

import React, { memo } from 'react';
import { connect } from 'react-redux';

import { IRootState, ISpArtList, TTypeCard } from '../../interfaces';
import { ASpArtListFetchRequest } from '../../modules/AppSpArtFilter/state/reducer/actions';
import AnimationList from '../../components/AnimationList/AnimationList';
import Loader from '../../components/Loader/Loader';
import ArtCard from '../../components/SpArtCard/SpArtCard';

import './style.scss';

interface Props {
  spArtListFetchRequestFn?: () => { type: string };
  spArtList?: ISpArtList;
  spArtListLoading?: boolean;
  error?: string;
  typeCard?: TTypeCard;
}

const SpArtList = ({
  spArtListFetchRequestFn,
  spArtList,
  spArtListLoading,
  typeCard = 'sp',
}: Props) => {
  //
  const handleClick = (e: any) => {
    e.preventDefault();
    spArtListFetchRequestFn!();
  };

  const spArtListWhithKey = spArtList!.map((item) => {
    return { ...item, id: item.art_id };
  });

  return (
    <>
      <div className="SpArtList masonry b-sp__pvb">
        <AnimationList
          list={spArtListWhithKey}
          classes="SpArtCard SpArtCard-anim"
          childList={ArtCard}
          childData={{ typeCard }}
        />
      </div>
      {spArtListLoading ? <Loader /> : null}
      <button className="grid-cols-24 button-section" onClick={handleClick}>
        Показать еще
      </button>
    </>
  );
};

function mapStateToProps(state: IRootState) {
  return {
    spArtList: state.moduleSpArtListFilter?.spArtList,
    spArtListLoading: state.moduleSpArtListFilter?.spArtListLoad,
    error: state.moduleSpArtListFilter?.error,
  };
}

export default connect(mapStateToProps, {
  spArtListFetchRequestFn: ASpArtListFetchRequest,
})(memo(SpArtList));

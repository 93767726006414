import { put, takeEvery, call, select } from 'redux-saga/effects';

import {
  SP_ART_LIST_FETCH_REQUESTED,
  SP_ART_LIST_FETCH_FAILED,
} from '../../../AppSpArtFilter/state/reducer/actionTypes';
import {
  ASpArtListLoadingTrue,
  ASpArtListFetchSucceeded,
  ASetStartIdSpArt,
  ASetIsEndSpArtTrue,
  ASetStartNumSQLListSpArt,
} from '../../../AppSpArtFilter/state/reducer/actions';
import { ISpArtList, IRootState, IAppSpArtList } from '../../../../interfaces';
import { getSpArtList } from './API';
import { IFetchSpDetalArtListData, IAppSpDetail } from '../interfaces';
import { IAppPriceFilter } from '../../../../containers/PriceFilter/state/interfaces';
import { IDetailFilter } from '../../../../containers/DetailFilters/state/interfaces';
import { timeout } from '../../../../common/getURLParam';

let timerId = 0;

export function* spListFetchRequest() {
  yield takeEvery(SP_ART_LIST_FETCH_REQUESTED, spListFetch);
}

function* spListFetch() {
  timerId = ++timerId;
  const currentTimer = timerId;

  yield put(ASpArtListLoadingTrue());
  yield timeout();

  const isGetRequest = currentTimer === timerId;

  if (isGetRequest) {
    const dataSend: IFetchSpDetalArtListData =
      yield getDataFromStateForSpListFetch();
    const { step: count, startStep: startNum } = dataSend;

    try {
      const splist: ISpArtList = yield call(getSpArtList, dataSend);
      yield setStartIdSp(splist, startNum);
      yield setIsEndSpTrue(splist.length, count);
      yield setStartNumSQLList(startNum, count);
      yield put(ASpArtListFetchSucceeded(splist));
    } catch (e) {
      console.error(e);

      //@ts-ignore
      if (e.message !== 'canceled') {
        //@ts-ignore
        yield put({ type: SP_ART_LIST_FETCH_FAILED, payload: e.message });
      }
    }
  }
}

function* getDataFromStateForSpListFetch() {
  const state: IRootState = yield select();
  const { moduleSpArtListFilter, priceFilter, moduleSpDetail, detailFilter } =
    state;
  const { startNumSqlList, countInSqlList } =
    moduleSpArtListFilter as IAppSpArtList;
  const { valueMax, valueMin } = priceFilter as IAppPriceFilter;
  const { artId, catId, spId } = moduleSpDetail as IAppSpDetail;
  const {
    selectSex: arrSelectSex,
    selectSize: arrSelectSize,
    inputText,
  } = detailFilter as IDetailFilter;
  const selectSize = arrSelectSize.join('|');
  const selectSex = arrSelectSex.join('|');

  return {
    artId,
    catId,
    color: '',
    spId,
    startStep: startNumSqlList,
    stc: catId > 0 ? 'y' : 'n',
    step: countInSqlList,
    valueMax,
    valueMin,
    selectSex,
    inputText,
    selectSize,
  } as IFetchSpDetalArtListData;
}

function* setStartIdSp(splist: ISpArtList, startNum: number) {
  if (startNum === 0 && splist.length > 0) {
    yield put(ASetStartIdSpArt(splist[0].topic_id));
  }
}

function* setIsEndSpTrue(length: number, count: number) {
  if (length < count) {
    yield put(ASetIsEndSpArtTrue());
  }
}

function* setStartNumSQLList(startNum: number, count: number) {
  const newStartNumSQLList = startNum + count;
  yield put(ASetStartNumSQLListSpArt(newStartNumSQLList));
}

import React, { FC } from 'react';
import MainSort from '../MainSort/MainSort';
import MainViewListSP from '../MainViewListSP/MainViewListSP';

interface Props {}

const SortMenu: FC<Props> = () => {
    return <div className="SortMenu">
        <MainSort />
        <MainViewListSP />
    </div>;
};

export default SortMenu;
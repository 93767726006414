import { IAction } from '../../../../interfaces';
import {
  SIZE_SELECT,
  SIZE_DESELECT,
  SEX_SELECT,
  TEXT_INPUT,
  SEX_DESELECT,
} from './actionTypes';
import { IDetailFilter } from '../interfaces';

const initialState: IDetailFilter = {
  selectSize: [],
  selectSex: [],
  inputText: '',
};

export default function (state = initialState, action: IAction) {
  switch (action.type) {
    case SIZE_SELECT:
      const newSizeVal = action.payload as string[];
      const newArrSize = [...state.selectSize, ...newSizeVal];
      return {
        ...state,
        selectSize: newArrSize,
      };
    case SEX_SELECT:
      const newSexVal = action.payload as string[];
      const newArrSex = [...state.selectSex, ...newSexVal];
      return {
        ...state,
        selectSex: newArrSex,
      };
    case TEXT_INPUT:
      const inputText = action.payload as string;
      return {
        ...state,
        inputText,
      };
    case SIZE_DESELECT:
      const delSizeVal = action.payload as string;
      const newDelArrSize = state.selectSize.filter(
        (val) => val !== delSizeVal
      );
      return {
        ...state,
        selectSize: newDelArrSize,
      };
    case SEX_DESELECT:
      const delSexVal = action.payload as string;
      const newDelArrSex = state.selectSex.filter((val) => val !== delSexVal);
      return {
        ...state,
        selectSex: newDelArrSex,
      };
    default:
      return state;
  }
}

import { put, takeEvery, call, select } from 'redux-saga/effects';

import {
  SP_LIST_FETCH_REQUESTED,
  SP_LIST_FETCH_FAILED,
} from '../reducer/actionTypes';
import {
  ASpListLoadingTrue,
  ASpListFetchSucceeded,
  ASetStartNumSQLList,
  ASetStartIdSp,
  ASetIsEndSpTrue,
} from '../reducer/actions';
import { ISpList, IFetchSpListData, IAppSpList } from '../interfaces';
import { getSpList } from './API';
import { IRootState } from '../../../../interfaces';
import { IAppCategoryFilter } from './../../../../containers/CategoryFilter/state/interfaces/index';
import { timeout } from '../../../../common/getURLParam';

let timerId = 0;

export function* spListFetchRequest() {
  yield takeEvery(SP_LIST_FETCH_REQUESTED, spListFetch);
}

function* getDataFromStateForSpListFetch() {
  const state: IRootState = yield select();
  const { categoryFilter, moduleSpListFilter } = state;
  const { selectSection, selectCat, selectDetCat } =
    categoryFilter as IAppCategoryFilter;
  const { startIdSp, startNumSqlList, countInSqlList, typeSp } =
    moduleSpListFilter as IAppSpList;

  // const dataSend = action.payload as IFetchSpListData;
  return {
    startIdSp,
    startNumSqlList,
    countInSqlList,
    section: selectSection,
    category: selectCat,
    categoryDetal: selectDetCat,
    typeSp,
  } as IFetchSpListData;
}

function* spListFetch() {
  timerId = ++timerId;
  const currentTimer = timerId;

  yield put(ASpListLoadingTrue());
  yield timeout();

  const isGetRequest = currentTimer === timerId;

  if (isGetRequest) {
    // const dataSend = action.payload as IFetchSpListData;
    const dataSend: IFetchSpListData = yield getDataFromStateForSpListFetch();
    const { countInSqlList: count, startNumSqlList: startNum } = dataSend;

    try {
      const splist: ISpList = yield call(getSpList, dataSend);

      yield setStartIdSp(splist, startNum);
      yield setIsEndSpTrue(splist.length, count);
      yield setStartNumSQLList(startNum, count);
      yield put(ASpListFetchSucceeded(splist));
    } catch (e) {
      console.error(e);
      yield put({ type: SP_LIST_FETCH_FAILED, payload: (e as Error).message });
    }
  }
}

function* setStartIdSp(splist: ISpList, startNum: number) {
  if (startNum === 0 && splist.length > 0) {
    yield put(ASetStartIdSp(splist[0].topic_id));
  }
}

function* setIsEndSpTrue(length: number, count: number) {
  if (length < count) {
    yield put(ASetIsEndSpTrue());
  }
}

function* setStartNumSQLList(startNum: number, count: number) {
  const newStartNumSQLList = startNum + count;
  yield put(ASetStartNumSQLList(newStartNumSQLList));
}

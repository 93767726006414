import React from 'react';
import { Provider } from 'react-redux';

import store from '../state/store';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import AppSpDetale from '../modules/AppSpDetale/AppSpDetale';

function AppSpDetales() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <AppSpDetale />
      </Provider>
    </ErrorBoundary>
  );
}

export default AppSpDetales;

// SpList
export const SP_LIST_FETCH_REQUESTED = 'SP_LIST_FETCH_REQUESTED';
export const SP_LIST_RESET = 'SP_LIST_RESET';
export const SP_LIST_FETCH_FAILED = 'SP_LIST_FETCH_FAILED';
export const SP_LIST_FETCH_SUCCEEDED = 'SP_LIST_FETCH_SUCCEEDED';
export const SP_LIST_LOADING_TRUE = 'SP_LIST_LOADING_TRUE';
export const SET_START_ID_SP = 'SET_START_ID_SP';
export const SET_START_NUM_SQL_LIST = 'SET_START_NUM_SQL_LIST';
export const SET_COUNT_IN_SQL_LIST = 'SET_COUNT_IN_SQL_LIST';
export const SET_IS_END_SP_TRUE = 'SET_IS_END_SP_TRUE';
export const SET_VIEW = 'SET_VIEW';
export const SET_TYPE_SP = 'SET_TYPE_SP';
// SpList end

import React, {
  ChangeEvent,
  FC,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { IRootState } from '../../interfaces';
import { connect } from 'react-redux';
import {
  IUnicSize,
  IUnicSex,
} from '../../modules/AppSpDetale/state/interfaces';
import MultiSelect from '../../components/MultiSelect/MultiSelect';

import './style.scss';
import {
  ASetSizeSelect,
  ASetSizeDeselect,
  ASetSexSelect,
  ASetTextSelect,
  ASetSexDeselect,
  ASetUpdateFilterSize,
  ASetUpdateFilterSex,
  ASetUpdateFilterText,
} from './state/reducer/actions';
import {
  updateSearchURL,
  deleteSearch,
  getSearch,
} from '../../common/getURLParam';

const FULL_TEXT = 'fullText';
const SEX = 'sex';
const SIZE = 'size';

interface Props {
  isSaveFiltersToUrl?: boolean;
  isSize?: boolean;
  isSex?: boolean;
  size?: IUnicSize;
  sex?: IUnicSex;
  filterSize?: string[];
  filterSex?: string[];
  filterText?: string;
  setSizeSelect: (val: string[]) => void;
  setSizeDeselect: (val: string) => void;
  setSexSelect: (val: string[]) => void;
  setTextSelect: (val: string) => void;
  setSexDeselect: (val: string) => void;
  setUpdateFilterSize: () => void;
  setUpdateFilterSex: () => void;
  setUpdateFilterText: () => void;
}

const DetailFilter: FC<Props> = ({
  isSize,
  isSex,
  size,
  sex,
  filterSize,
  filterSex,
  filterText,
  isSaveFiltersToUrl,
  setSizeSelect,
  setSizeDeselect,
  setTextSelect,
  setSexSelect,
  setSexDeselect,
  setUpdateFilterSize,
  setUpdateFilterSex,
  setUpdateFilterText,
}) => {
  const [textTimerId, setTextTimerId] = useState<any>();
  const [searchValue, setSearchValue] = useState('');
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  let arrListSex: string[] = [];

  if (sex) {
    arrListSex = Object.keys(sex).map((id) => {
      return sex[id] as string;
    });
  }

  const handleOnChangeSize = (name: string, val: boolean) => {
    setIsFirstLoading(false);

    if (val) {
      setSizeSelect([name]);
    } else {
      setSizeDeselect(name);
    }
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(textTimerId);

    const val = e.currentTarget.value;

    setSearchValue(val);

    if (Boolean(val)) {
      updateSearchURL(FULL_TEXT, val);
    } else {
      deleteSearch(FULL_TEXT);
    }

    const timerId = setTimeout(() => {
      if (val.length > 2 || val === '') {
        setTextSelect(val.trim());
      }
    }, 2000);

    setTextTimerId(timerId);
  };

  const handleOnChangeSex = (name: string, val: boolean) => {
    setIsFirstLoading(false);

    if (val) {
      setSexSelect([name]);
    } else {
      setSexDeselect(name);
    }
  };

  useEffect(() => {
    return () => {
      if (textTimerId) {
        clearTimeout(textTimerId);
      }
    };
  }, []);

  useEffect(() => {
    setUpdateFilterSize();

    if (!isFirstLoading) {
      if (filterSize && filterSize.length > 0) {
        updateSearchURL(SIZE, filterSize.join('|'));
      } else {
        deleteSearch(SIZE);
      }
    }
  }, [filterSize, setUpdateFilterSize]);

  useEffect(() => {
    setUpdateFilterSex();

    if (!isFirstLoading) {
      if (filterSex && filterSex.length > 0) {
        updateSearchURL(SEX, filterSex.join('|'));
      } else {
        deleteSearch(SEX);
      }
    }
  }, [filterSex, setUpdateFilterSex]);

  useEffect(() => {
    setUpdateFilterText();
  }, [filterText, setUpdateFilterText]);

  useLayoutEffect(() => {
    const fullText = getSearch(FULL_TEXT);

    if (fullText) {
      setSearchValue(fullText.trim());
      setTextSelect(fullText.trim());
    }

    const sex = getSearch(SEX)?.split('|');

    if (sex) {
      setSexSelect(sex);
    }

    const size = getSearch(SIZE)?.split('|');

    if (size) {
      setSizeSelect(size);
    }
  }, []);

  return (
    <>
      <div className="DetailFilter">
        <div className="DetailFilter__name">
          <input
            placeholder="Введите строку поиска"
            onChange={handleChangeName}
            value={searchValue}
          />
        </div>
      </div>
      <div className="DetailFilter">
        <div className="DetailFilter__size">
          {size && isSize && (
            <MultiSelect
              items={size}
              defaultValue={getSearch(SIZE)?.split('|')}
              onChangeItem={handleOnChangeSize}
              label="Размеры"
            />
          )}
        </div>
        <div className="DetailFilter__sex">
          {sex && isSex && (
            <MultiSelect
              defaultValue={getSearch(SEX)?.split('|')}
              items={arrListSex}
              onChangeItem={handleOnChangeSex}
              label="Пол"
            />
          )}
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state: IRootState) {
  return {
    isSize: state.moduleSpDetail?.spDetail.filters.size,
    isSex: state.moduleSpDetail?.spDetail.filters.sex,
    size: state.moduleSpDetail?.spDetail.unicSize,
    sex: state.moduleSpDetail?.spDetail.unicSex,
    filterSize: state.detailFilter?.selectSize,
    filterSex: state.detailFilter?.selectSex,
    filterText: state.detailFilter?.inputText,
  };
}

export default connect(mapStateToProps, {
  setSizeSelect: ASetSizeSelect,
  setSizeDeselect: ASetSizeDeselect,
  setSexSelect: ASetSexSelect,
  setTextSelect: ASetTextSelect,
  setSexDeselect: ASetSexDeselect,
  setUpdateFilterSize: ASetUpdateFilterSize,
  setUpdateFilterSex: ASetUpdateFilterSex,
  setUpdateFilterText: ASetUpdateFilterText,
})(DetailFilter);

import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import PriceSlider from './components/PriceSlider/PriceSlider';
import { IRootState } from '../../interfaces';
import {
  ASelectRangePrice,
  ASetStepPrice,
  ASetMinPrice,
  ASetMaxPrice,
  ASetMinValPrice,
  ASetMaxValPrice,
  APriceFilterUpdate,
  APriceFilterMoreThanMaxUpdate,
} from './state/reducers/actions';
import { IPriceRange } from './state/interfaces';
import './style.scss';
import getURLParams, {
  setUrl,
  updateSearch,
  urldecode,
  updateSearchURL,
  deleteSearch,
  getSearch,
} from '../../common/getURLParam';

const MIN_PRICE = 'minPrice';
const MAX_PRICE = 'maxPrice';

interface Props {
  isSaveFiltersToUrl?: boolean;
  min?: number;
  max?: number;
  step?: number;
  minVal?: number;
  maxVal?: number;
  setapStep?: number;
  more?: boolean;
  setapMin?: number;
  setapMax?: number;
  setMinPrice?(min: number): void;
  setMaxPrice?(max: number): void;
  setMinValPrice?(min: number): void;
  setMaxValPrice?(max: number): void;
  priceFilterUpdate?(): void;
  selectRangePrice?(price: IPriceRange): void;
  setStepPrice?(step: number): void;
  priceFilterMoreThanMaxUpdate?(more: boolean): void;
}

const PriceFilter = (props: Props) => {
  const {
    min,
    max,
    step,
    minVal,
    maxVal,
    isSaveFiltersToUrl,
    selectRangePrice,
    setapStep,
    setapMin,
    setapMax,
    more = false,
    setStepPrice,
    setMinPrice,
    setMaxPrice,
    priceFilterUpdate,
    setMinValPrice,
    setMaxValPrice,
    priceFilterMoreThanMaxUpdate,
  } = props;

  useLayoutEffect(() => {
    if (isSaveFiltersToUrl) {
      setTimeout(() => {
        const minPriceString = getSearch(MIN_PRICE);
        const maxPriceString = getSearch(MAX_PRICE);
        const minPrice = minPriceString ? parseFloat(minPriceString) : null;
        const maxPrice = maxPriceString ? parseFloat(maxPriceString) : null;

        if (minPrice) {
          setMinValPrice!(minPrice);
        }
        if (maxPrice) {
          setMaxValPrice!(maxPrice);
        }
        
        priceFilterUpdate!();
      }, 1000);
    } else {
      const minPrice = parseInt(urldecode(getURLParams(MIN_PRICE)).trim());
      const maxPrice = parseInt(urldecode(getURLParams(MAX_PRICE)).trim());

      selectRangePrice!({
        max: maxPrice ? maxPrice : max!,
        min: minPrice ? minPrice : min!,
      });
    }

    priceFilterUpdate!();

    if (setapStep) {
      setStepPrice!(setapStep);
    }
    if (setapMin) {
      setMinValPrice!(setapMin);
      setMinPrice!(setapMin);
    }
    if (setapMax) {
      setMaxValPrice!(setapMax);
      setMaxPrice!(setapMax);
    }
    if (more) {
      priceFilterMoreThanMaxUpdate!(more);
    }
    // eslint-disable-next-line
  }, []);

  const handleCange = (price: IPriceRange) => {
    if (isSaveFiltersToUrl) {
      if (price.min !== 0) {
        updateSearchURL(MIN_PRICE, price.min.toString());
      } else {
        deleteSearch(MIN_PRICE);
      }
      if (price.max !== maxVal) {
        updateSearchURL(MAX_PRICE, price.max.toString());
      } else {
        deleteSearch(MAX_PRICE);
      }

      selectRangePrice!(price);
      priceFilterUpdate!();
    } else {
      if (price.min !== minVal || price.max !== maxVal) {
        const priceData = `${price.min === min ? '0' : price.min}=${
          price.max === max ? '0' : price.max
        }`;

        // console.log('minVal', minVal);
        // console.log('maxVal', maxVal);
        // console.log('price.min', price.min);
        // console.log('price.max', price.max);
        // console.log(priceData);

        const searchStr = updateSearch('price', priceData);
        setUrl(searchStr);

        selectRangePrice!(price);
        priceFilterUpdate!();
      } else {
        const searchStr = updateSearch('price', '0=0');
        setUrl(searchStr);
      }
    }
  };

  return (
    <div className="PriceFilter">
      <PriceSlider
        min={min!}
        max={max!}
        step={step!}
        value={[minVal!, maxVal!]}
        more={more}
        onChange={handleCange}
      />
    </div>
  );
};

function mapStateToProps(state: IRootState) {
  return {
    max: state.priceFilter?.priceMax,
    min: state.priceFilter?.priceMin,
    maxVal: state.priceFilter?.valueMax,
    minVal: state.priceFilter?.valueMin,
    step: state.priceFilter?.step,
  };
}

export default connect(mapStateToProps, {
  selectRangePrice: ASelectRangePrice,
  setMinPrice: ASetMinPrice,
  setMaxPrice: ASetMaxPrice,
  setMinValPrice: ASetMinValPrice,
  setMaxValPrice: ASetMaxValPrice,
  setStepPrice: ASetStepPrice,
  priceFilterUpdate: APriceFilterUpdate,
  priceFilterMoreThanMaxUpdate: APriceFilterMoreThanMaxUpdate,
})(PriceFilter);

export const CATEGORY_FILTER_UPDATE = 'CATEGORY_FILTER_UPDATE';
export const ALL_RESET = 'ALL_RESET';
// Section
export const SECTION_FETCH_REQUESTED = 'SECTION_FETCH_REQUESTED';
export const SECTION_FETCH_FAILED = 'SECTION_FETCH_FAILED';
export const SECTION_FETCH_SUCCEEDED = 'SECTION_FETCH_SUCCEEDED';
export const SECTION_SELECT = 'SECTION_SELECT';
export const SECTION_LOADING_TRUE = 'SECTION_LOADING_TRUE';
export const SECTION_ALL_RESET = 'SECTION_ALL_RESET';
// Section end

// Category
export const CATEGORY_FETCH_REQUESTED = 'CATEGORY_FETCH_REQUESTED';
export const CATEGORY_FETCH_FAILED = 'CATEGORY_FETCH_FAILED';
export const CATEGORY_FETCH_SUCCEEDED = 'CATEGORY_FETCH_SUCCEEDED';
export const CATEGORY_SELECT = 'CATEGORY_SELECT';
export const CATEGORY_LOADING_TRUE = 'CATEGORY_LOADING_TRUE';
export const CATEGORY_ALL_RESET = 'CATEGORY_ALL_RESET';
// Category end

// Detail category
export const DETAIL_CATEGORY_FETCH_REQUESTED =
  'DETAIL_CATEGORY_FETCH_REQUESTED';
export const DETAIL_CATEGORY_FETCH_FAILED = 'DETAIL_CATEGORY_FETCH_FAILED';
export const DETAIL_CATEGORY_FETCH_SUCCEEDED =
  'DETAIL_CATEGORY_FETCH_SUCCEEDED';
export const DETAIL_CATEGORY_SELECT = 'DETAIL_CATEGORY_SELECT';
export const DETAIL_CATEGORY_LOADING_TRUE = 'DETAIL_CATEGORY_LOADING_TRUE';
export const DETAIL_CATEGORY_ALL_RESET = 'DETAIL_CATEGORY_ALL_RESET';
// Detail category end

// Detail category
export const TEXT_SEARCH_FETCH_REQUESTED = 'TEXT_SEARCH_FETCH_REQUESTED';
export const TEXT_SEARCH_FETCH_FAILED = 'TEXT_SEARCH_FETCH_FAILED';
export const TEXT_SEARCH_FETCH_SUCCEEDED = 'TEXT_SEARCH_FETCH_SUCCEEDED';
export const TEXT_SEARCH_ON_CHANGE = 'TEXT_SEARCH_ON_CHANGE';
export const TEXT_SEARCH_LOADING_TRUE = 'TEXT_SEARCH_LOADING_TRUE';
// Detail category end

import { IAction } from './../../../../interfaces';
import { IAppCategoryFilter } from './../interfaces';

import {
  ALL_RESET,
  SECTION_FETCH_SUCCEEDED,
  SECTION_SELECT,
  SECTION_FETCH_FAILED,
  CATEGORY_FETCH_FAILED,
  DETAIL_CATEGORY_FETCH_FAILED,
  CATEGORY_FETCH_SUCCEEDED,
  CATEGORY_SELECT,
  DETAIL_CATEGORY_FETCH_SUCCEEDED,
  DETAIL_CATEGORY_SELECT,
  SECTION_LOADING_TRUE,
  CATEGORY_LOADING_TRUE,
  DETAIL_CATEGORY_LOADING_TRUE,
  TEXT_SEARCH_ON_CHANGE,
  SECTION_ALL_RESET,
  CATEGORY_ALL_RESET,
  DETAIL_CATEGORY_ALL_RESET,
} from '../reducer/actionTypes';

const initialState: IAppCategoryFilter = {
  selectSection: 0,
  sectionLoad: false,
  selectCat: 0,
  catLoad: false,
  selectDetCat: 0,
  detCatLoad: false,
  textSearch: '',
  sections: [],
  categoryes: [],
  detailCategoryes: [],
  error: '',
};

export default function (state = initialState, action: IAction) {
  switch (action.type) {
    case ALL_RESET:
      return {...state, selectSection: 0, selectCat: 0, selectDetCat: 0, categoryes: [], detailCategoryes: []}
    case SECTION_FETCH_SUCCEEDED:
      return {
        ...state,
        sections: action.payload,
        error: '',
        sectionLoad: false,
      };
    case SECTION_ALL_RESET:
      return {...state, selectSection: 0, selectCat: 0, selectDetCat: 0, categoryes: [], detailCategoryes: []}
    case SECTION_SELECT:
      return { ...state, selectSection: action.payload };
    case SECTION_LOADING_TRUE:
      return { ...state, sectionLoad: true };
    case CATEGORY_FETCH_SUCCEEDED:
      return {
        ...state,
        categoryes: action.payload,
        error: '',
        catLoad: false,
      };
    case CATEGORY_SELECT:
      return { ...state, selectCat: action.payload };
    case CATEGORY_LOADING_TRUE:
      return { ...state, catLoad: true };
    case DETAIL_CATEGORY_FETCH_SUCCEEDED:
      return {
        ...state,
        detailCategoryes: action.payload,
        error: '',
        detCatLoad: false,
      };
    case CATEGORY_ALL_RESET: 
      return {...state, selectCat: 0, selectDetCat: 0, detailCategoryes: []}
    case DETAIL_CATEGORY_SELECT:
      return { ...state, selectDetCat: action.payload };
    case DETAIL_CATEGORY_LOADING_TRUE:
      return { ...state, detCatLoad: true };
    case SECTION_FETCH_FAILED:
    case CATEGORY_FETCH_FAILED:
    case DETAIL_CATEGORY_FETCH_FAILED:
      return {
        ...state,
        error: action.payload,
        sectionLoad: false,
        catLoad: false,
        detCatLoad: false,
        spListLoad: false,
      };
    case DETAIL_CATEGORY_ALL_RESET: 
      return {...state, selectDetCat: 0}
    case TEXT_SEARCH_ON_CHANGE:
      return { ...state, textSearch: action.payload };

    default:
      return state;
  }
}

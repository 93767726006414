import { put, takeEvery, call } from 'redux-saga/effects';
import {
  CATEGORY_FETCH_REQUESTED,
  CATEGORY_FETCH_FAILED,
} from '../reducer/actionTypes';
import { IAction } from './../../../../interfaces/index';
import { ICategory } from './../interfaces/index';
import { getCategory } from './API';
import {
  ACategoryFetchSucceeded,
  ACategoryLoadingTrue,
} from '../reducer/actions';

export function* categoryFetchRequest() {
  yield takeEvery(CATEGORY_FETCH_REQUESTED, categoryFetch);
}

function* categoryFetch(action: IAction) {
  yield put(ACategoryLoadingTrue());
  try {
    const category: ICategory[] = yield call(
      getCategory,
      action.payload as number
    );
    yield put(ACategoryFetchSucceeded(category));
  } catch (e) {
    console.error(e);

    //@ts-ignore
    if (e.message !== 'canceled') {
      //@ts-ignore
      yield put({ type: CATEGORY_FETCH_FAILED, payload: e.message });
    }
  }
}

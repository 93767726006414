import React, { memo, ReactElement, useState } from 'react';
import { ISPCard } from '../../modules/AppCategoryFilter/state/interfaces';

interface Props {
  data: ISPCard;
}

const SpCard = ({ data }: Props): ReactElement => {
  const {
    alt_img,
    number_of_orders,
    class_label,
    ico_label,
    // is_end,
    link_detal_sp,
    link_org_prof,
    name_org,
    forum_name,
    // number,
    // participants,
    // rating,
    size,
    src_img,
    title,
    title_img,
    // topic_id,
    // users_in_rating,
  } = data;
  const [isLoadImg, setLoadImg] = useState(true);
  // console.count('SpCard');

  const myHandleImageLoadEvent = (event: any) => {
    setLoadImg(false);
  };

  return (
    <div className={`${size}`}>
      <div className={`b-sp__vb-group ${class_label}`}>
        <div className="b-sp__lable">
          <a
            className="b-sp__vb-image"
            // target="_blank"
            href={link_detal_sp}
            // rel="noreferrer noopener"
          >
            <div className="b-sp__lable-content positioning-block__yes">
              <a
                className="b-sp__vb-image"
                // target="_blank"
                href={link_detal_sp}
                // rel="noreferrer noopener"
              >
                <div className="b-sp__lable-title">{title}</div>
              </a>
              <a
                className="b-sp__lable-org-name"
                href={link_org_prof}
                // target="_blank"
                // rel="noreferrer noopener"
              >
                {name_org}
              </a>
              <span className="b-sp__lable-icon-group">
                <i className={`icon-size-m icon-position-m ${ico_label}`}></i>
              </span>
            </div>
          </a>
        </div>
        <a
          className="b-sp__vb-image"
          // target="_blank"
          href={link_detal_sp}
          // rel="noreferrer noopener"
        >
          <img
            style={{
              display: isLoadImg ? 'none' : 'block',
            }}
            // width="100%"
            // height={spCardData.size === 'normal' ? '400' : '170'}
            onLoad={myHandleImageLoadEvent}
            title={title_img}
            alt={alt_img}
            src={src_img}
          />
          {forum_name ? <div style={{display: "none"}}>{forum_name}</div> : null}
        </a>
      </div>
    </div>
  );
};

export default memo(SpCard);

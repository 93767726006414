import React from 'react';
import { connect } from 'react-redux';

import { IRootState } from '../../interfaces';
import { ISpList } from '../../modules/AppCategoryFilter/state/interfaces';
import { ASpListFetchRequest } from '../../modules/AppCategoryFilter/state/reducer/actions';
import SpCard from '../../components/SpCard';

import './style.scss';
import AnimationList from '../../components/AnimationList/AnimationList';
import Loader from '../../components/Loader/Loader';

function SpList(props: any) {
  const {
    spListFetchRequestFn,
    spList,
    spListLoad,
  }: // error,
  {
    spListFetchRequestFn: Function;
    spList: ISpList;
    spListLoad: boolean;
    error: string;
  } = props;

  const handleClick = (e: any) => {
    e.preventDefault();
    spListFetchRequestFn();
  };

  const spListWhithKey = spList.map((item) => {
    return { ...item, id: item.topic_id };
  });

  return (
    <>
      <div className="SpList masonry b-sp__vb">
        <AnimationList
          list={spListWhithKey}
          classes="SpCard SpCard-anim"
          childList={SpCard}
        />
      </div>
      {spListLoad ? <Loader /> : null}
      <button className="grid-cols-24 button-section" onClick={handleClick}>
        Показать еще
      </button>
    </>
  );
}

function mapStateToProps(state: IRootState) {
  return {
    spList: state.moduleSpListFilter?.spList,
    spListLoad: state.moduleSpListFilter?.spListLoad,
    error: state.moduleSpListFilter?.error,
  };
}

export default connect(mapStateToProps, {
  spListFetchRequestFn: ASpListFetchRequest,
})(SpList);

import { IAction } from '../../../../interfaces/index';
import { IAppSpList, MainSortSP, ViewListSP } from '../interfaces';
import {
  SET_COUNT_IN_SQL_LIST,
  SET_IS_END_SP_TRUE,
  SET_START_ID_SP,
  SET_START_NUM_SQL_LIST,
  SET_TYPE_SP,
  SET_VIEW,
  SP_LIST_FETCH_FAILED,
  SP_LIST_FETCH_SUCCEEDED,
  SP_LIST_LOADING_TRUE,
  SP_LIST_RESET,
} from './actionTypes';

const initialState: IAppSpList = {
  spList: [],
  spListLoad: false,
  startIdSp: 0,
  startNumSqlList: 0,
  countInSqlList: 60,
  isEndSp: false,
  error: '',
  typeSp: MainSortSP.NewSP,
  isGreedView: ViewListSP.Greed,
};

export default function (state = initialState, action: IAction) {
  switch (action.type) {
    case SP_LIST_RESET:
      return { ...state, spList: [], isEndSp: false };
    case SP_LIST_FETCH_SUCCEEDED:
      const newSpList = [...state.spList, ...action.payload];
      return { ...state, spList: newSpList, spListLoad: false };
    case SP_LIST_LOADING_TRUE:
      return { ...state, spListLoad: true };
    case SET_IS_END_SP_TRUE:
      return { ...state, isEndSp: true, spListLoad: false };
    case SET_START_ID_SP:
      return { ...state, startIdSp: action.payload };
    case SET_VIEW:
      return { ...state, isGreedView: action.payload };
    case SET_TYPE_SP:
      return { ...state, typeSp: action.payload };
    case SET_START_NUM_SQL_LIST:
      return { ...state, startNumSqlList: action.payload };
    case SET_COUNT_IN_SQL_LIST:
      return { ...state, countInSqlList: action.payload };
    case SP_LIST_FETCH_FAILED:
      return {
        ...state,
        error: action.payload,
        sectionLoad: false,
        catLoad: false,
        detCatLoad: false,
        spListLoad: false,
      };
    default:
      return state;
  }
}

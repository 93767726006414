import React, {
  ReactElement,
  StatelessComponent,
  MemoExoticComponent,
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { IList } from './../../interfaces/index';

type IChildList = (props: { data: any }) => ReactElement;

interface Props {
  list: IList;
  childList:
    | MemoExoticComponent<(props: { data: any }) => ReactElement>
    | IChildList;
  classes: string;
  childData?: {};
}

export default function AnimationList({
  list,
  childList,
  classes,
  childData,
}: Props): ReactElement {
  return (
    <TransitionGroup>
      {list.map((item, index) => {
        return (
          <CSSTransition key={item.id} timeout={500} classNames={classes}>
            {React.createElement(
              childList as StatelessComponent<{ data: {} }>,
              {
                data: item,
                ...childData,
              }
            )}
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
}

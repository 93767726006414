import { put, takeEvery } from 'redux-saga/effects';

import { CATEGORY_FILTER_UPDATE } from '../../../../containers/CategoryFilter/state/reducer/actionTypes';
import {
  ASetStartNumSQLListSpArt,
  ASetStartIdSpArt,
  ASpArtListReset,
  ASpArtListFetchRequest,
} from '../reducer/actions';
import { PRICE_FILTER_UPDATE } from '../../../../containers/PriceFilter/state/reducers/actionTypes';

export function* spListUpdate() {
  yield takeEvery(CATEGORY_FILTER_UPDATE, updateSpList);
  yield takeEvery(PRICE_FILTER_UPDATE, updateSpList);
}

function* updateSpList() {
  yield put(ASpArtListReset());
  yield put(ASetStartIdSpArt(0));
  yield put(ASetStartNumSQLListSpArt(0));
  yield put(ASpArtListFetchRequest());
}

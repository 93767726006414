import axios from 'axios';
import { IFetchSpListData } from '../interfaces';
import isFackeData from '../../../../getDateArea';

let controller = new AbortController();

export const getSpList = async (dataSend: IFetchSpListData) => {
  const {
    startNumSqlList,
    countInSqlList,
    startIdSp,
    section,
    category,
    categoryDetal,
    typeSp,
  } = dataSend;
  let sentCatId = '';

  if (categoryDetal) {
    sentCatId = `&cd=${categoryDetal}`;
  } else if (category) {
    sentCatId = `&c=${category}`;
  } else if (section) {
    sentCatId = `&sc=${section}`;

    controller.abort();
    controller = new AbortController();
  }

  let strUrl = '';

  if (isFackeData) {
    strUrl = `/APIFilterSps/splist.json?type=filter${sentCatId}&s=${startNumSqlList}&n=${countInSqlList}&idSp=${startIdSp}&spType=${typeSp}`;
  } else {
    strUrl = `/APIFilterSps/?type=filter${sentCatId}&s=${startNumSqlList}&n=${countInSqlList}&idSp=${startIdSp}&spType=${typeSp}`;
  }

  if (categoryDetal === 0 && category === 0 && section === 0) {
    if (isFackeData) {
      strUrl = `/APIFilterSps/splist.json?type=filter2${sentCatId}&s=${startNumSqlList}&n=${countInSqlList}&idSp=${startIdSp}&spType=${typeSp}`;
    } else {
      strUrl = `/APIFilterSps/?s=${startNumSqlList}&n=${countInSqlList}&idSp=${startIdSp}&spType=${typeSp}`;
    }
  }

  return getHttpDate(strUrl, controller.signal);
};

async function getHttpDate(strUrl: string, signal?: AbortSignal) {
  const res = await axios.get(strUrl, { signal });
  const { data } = res;
  return data;
}

import React from "react";
import { connect } from "react-redux";
import { setUrl, updateSearch } from "../../../common/getURLParam";
import Select from "../../../components/Select";
import { IRootState } from "../../../interfaces";
import { ASelectDetailCategory } from "../state/reducer/actions";

function DetailCategoryInput(props: any) {
  const { selectDetCat, detailCategoryes, selectDetailCategoryFn, detCatLoad } =
    props;
  const defaultValue = "3. ВЫБЕРИТЕ НАИМЕНОВАНИЕ";

  const handleClick = (dctegoryId: number) => {
    selectDetailCategoryFn(dctegoryId);

    const searchStr = updateSearch(
      "dcategory",
      dctegoryId > 0 ? `${dctegoryId}` : "Не выбрано"
    );

    setUrl(searchStr);
  };

  return (
    <div
      className={`DetailCategoryInput ${
        detailCategoryes.length > 1 ? "" : "SectionHide"
      }`}
    >
      <Select
        optionsList={detailCategoryes}
        selectId={selectDetCat}
        handleClick={handleClick}
        defaultLabel={defaultValue}
        className="Select"
        disabled={detCatLoad}
        defaultClickable
      />
    </div>
  );
}

function mapStateToProps(state: IRootState) {
  return {
    selectDetCat: state.categoryFilter?.selectDetCat,
    detailCategoryes: state.categoryFilter?.detailCategoryes,
    detCatLoad: state.categoryFilter?.detCatLoad,
  };
}

export default connect(mapStateToProps, {
  selectDetailCategoryFn: ASelectDetailCategory,
})(DetailCategoryInput);

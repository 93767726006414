import React, { FC, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../interfaces';
import { ViewListSP } from '../../modules/AppCategoryFilter/state/interfaces';
import { ASetView } from '../../modules/AppCategoryFilter/state/reducer/actions';

interface Props {
  greedView?: ViewListSP;
  setView: typeof ASetView;
}

const MainViewListSP: FC<Props> = ({
  greedView = ViewListSP.Greed,
  setView,
}) => {
  const handleOnClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    if (greedView !== ViewListSP[ViewListSP.Greed]) {
      setView(ViewListSP.Greed);
    } else {
      setView(ViewListSP.Line);
    }
  };

  const iconButtons = {
    [ViewListSP[ViewListSP.Greed]]: 'icon-greed',
    [ViewListSP[ViewListSP.Line]]: 'icon-line',
  };

  return (
    <ul className="MainViewListSP">
      <li className={'MainViewListSP__button_active'}>
        <a href="#" onClick={handleOnClick}>
          <div className="moduletable_label">
            <i className={`icon ${iconButtons[greedView]}`}></i>
          </div>
        </a>
      </li>
    </ul>
  );
};

function mapStateToProps(state: IRootState) {
  return {
    greedView: state.moduleSpListFilter?.isGreedView,
  };
}

export default connect(mapStateToProps, {
  setView: ASetView,
})(MainViewListSP);

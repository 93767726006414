import {
  SP_ART_LIST_FETCH_SUCCEEDED,
  SP_ART_LIST_FETCH_REQUESTED,
  SP_ART_LIST_LOADING_TRUE,
  SP_ART_LIST_RESET,
  SET_START_ID_SP_ART,
  SET_START_NUM_SQL_LIST_SP_ART,
  SET_COUNT_IN_SQL_LIST_SP_ART,
  SET_IS_END_SP_TRUE_SP_ART,
  SET_CANCEL_TOKEN_SOURCE,
  SET_CANCEL_TOKEN_SOURCE_RESET,
  SET_LAST_PATH,
} from './actionTypes';
import { ISpArtList } from '../../../../interfaces';

// SpList
export function ASpArtListReset() {
  return { type: SP_ART_LIST_RESET };
}
export function ASpArtListFetchSucceeded(spArtList: ISpArtList) {
  return { type: SP_ART_LIST_FETCH_SUCCEEDED, payload: spArtList };
}
export function ASpArtListFetchRequest() {
  return { type: SP_ART_LIST_FETCH_REQUESTED };
}
export function ASpArtListLoadingTrue() {
  return { type: SP_ART_LIST_LOADING_TRUE };
}
export function ASetIsEndSpArtTrue() {
  return { type: SET_IS_END_SP_TRUE_SP_ART };
}
export function ASetStartIdSpArt(id: number) {
  return { type: SET_START_ID_SP_ART, payload: id };
}
export function ASetLastPath(laastPath: string) {
  return { type: SET_LAST_PATH, payload: laastPath };
}
export function ASetStartNumSQLListSpArt(num: number) {
  return { type: SET_START_NUM_SQL_LIST_SP_ART, payload: num };
}
export function ASetCountInSQLListSpArt(num: number) {
  return { type: SET_COUNT_IN_SQL_LIST_SP_ART, payload: num };
}

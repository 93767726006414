import React, { memo, ReactElement } from 'react';
import { ISpArtCard, ISize, TTypeCard } from '../../interfaces';

interface Props {
  data: ISpArtCard;
  typeCard?: TTypeCard;
}

const ArtCard = (props: Props): ReactElement => {
  const urlParams = new URLSearchParams(window.location.search);
  const fullText = urlParams.get('fullText');
  const { data, typeCard } = props;
  const {
    number_of_orders,
    class_label,
    name_org,
    topic_id,
    art_id,
    src_img,
    brand,
    type,
    price,
    old_price,
    val,
    cat_id,
    // ico_label,
    // link_detal_sp,
    // link_org_prof,
    // number,
    // org_id,
    // size_rows,
    title,
  } = data;
  const sizesHtml = data.size ? getSizes(data.size_title, data.size) : '';
  const colorsHtml = data.color ? getColors(data.color) : '';
  const cartHtml =
    data.color && data.color.length <= 1 && data.size && data.size.length <= 1 && typeCard !== 'sp'
      ? getCart(topic_id, art_id, data)
      : '';
  const sexHtml = data.sex ? getSex(data.sex) : '';
  const materialHtml = data.material ? getMaterial(data.material) : '';
  const commentHtml = data.comment ? getComment(data.comment) : '';
  const linkCard =
    typeCard === 'sp'
      ? `/index.php/magazin/sovmestnaya-pokupka-detalno?sp=${topic_id}&art=${art_id}&cat=${cat_id}${
          fullText ? `&fullText=${fullText}` : ''
        }`
      : `/index.php/magazin/lot-zakupki-detalno?sp=${topic_id}&art=${art_id}${
          fullText ? `&fullText=${fullText}` : ''
        }`;

  return (
    <div className="element-item">
      <div className={`b-sp__pvb-item ${class_label}`}>
        <div className="b-sp__lable-org">
          <div className="b-sp__lable-org-content positioning-block__yes">
            <div className="b-sp__lable-org-info">
              <div className="b-sp__pvb-type">{type}</div>
            </div>
          </div>
        </div>
        <a
          // target="_blank"
          className="b-sp__pvb-link"
          // rel="noopener noreferrer"
          href={linkCard}
        >
          <div className="b-sp__pvb-image-container">
            <img className="b-sp__pvb-image" title="" alt="" src={src_img} />
          </div>

          <div className="b-sp__pvb-brand">{brand}</div>
          {typeCard === 'sp' && (
            <div className="b-sp__lable-org-name">{name_org}</div>
          )}
          {typeCard === 'sp' && (
            <div className="b-sp__lable-sp-name">{title}</div>
          )}
          <div className="b-sp__pvb-price-container">
            <div className="b-sp__pvb-price">
              {price} <span>{val}</span>
            </div>
            {!!old_price ? (
              <div className="b-sp__pvb-old-price">
                {old_price} <span>{val}</span>
              </div>
            ) : null}
          </div>
        </a>
        <div className="b-sp__pvb-cart-button-container">{cartHtml}</div>
        <div className="b-sp__pvb-info-more">
          <div className="b-sp__pvb-info-more-wrap">
            {Boolean(number_of_orders) ? (
              <div className="b-sp__pvb-sizes">
                <span className="b-sp__pvb-sizes-title">Кол-во заказов: </span>
                <a className="b-sp__pvb-size-item size" href="/#">
                  {number_of_orders}
                </a>
              </div>
            ) : null}
            {sizesHtml}
            {colorsHtml}
            {sexHtml}
            {materialHtml}
            {commentHtml}
          </div>
        </div>
      </div>
    </div>
  );
};

function getSizes(size_title: string, sizes: ISize[]): ReactElement {
  if (sizes.length > 0) {
    return (
      <div className="b-sp__pvb-sizes">
        <span className="b-sp__pvb-sizes-title">Размеры ({size_title}): </span>
        {sizes.map((size) => (
          <a
            className="b-sp__pvb-size-item size"
            href="/#"
            key={`${size.value} - ${size.amount}`}
          >
            {size.value} {size.amount}
          </a>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
}

function getColors(colors: string[]): ReactElement {
  if (colors.length > 0) {
    return (
      <div className="b-sp__pvb-color">
        <span className="b-sp__pvb-color-title">Цвета: </span>
        <br />
        {colors.map((color) => (
          <a className="b-sp__pvb-color-item" href="/#" key={color}>
            {color}
          </a>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
}

function getCart(topic_id: number, art_id: number, data: ISpArtCard) {
  return (
    <button
      data-sp-num={`${topic_id}`}
      data-sp-art-num={`${art_id}`}
      type="button"
      className="sp_but_add_art_in_basket_in_header sp_but_add_art_in_basket_in_sp"
    >
      Добавить в корзину
    </button>
  );
}

function getSex(sex: string): ReactElement {
  if (sex) {
    return (
      <div className="b-sp__pvb-sex">
        <span className="b-sp__pvb-sex-title">{sex}</span>
      </div>
    );
  } else {
    return <></>;
  }
}

function getMaterial(material: string): ReactElement {
  if (material) {
    return (
      <div className="b-sp__pvb-material">
        <span className="b-sp__pvb-material-title">Материал: {material}</span>
      </div>
    );
  } else {
    return <></>;
  }
}

function getComment(comment: string): ReactElement {
  if (comment) {
    return (
      <div className="b-sp__pvb-comment">
        <span className="b-sp__pvb-comment-title">{comment}</span>
      </div>
    );
  } else {
    return <></>;
  }
}

export default memo(ArtCard);

import { IAction } from '../../../../interfaces/index';
import { IAppSpDetail } from '../interfaces';

import {
  SP_DETAIL_ADD_ACTUAL_ART_ID,
  SP_DETAIL_ADD_ACTUAL_CAT_ID,
  SP_DETAIL_ADD_ACTUAL_SP_ID,
  SP_DETAIL_ADD_DATA_SP,
  SP_DETAIL_ADD_DATA_SP_CONDITIONS,
} from './actionTypes';

const initialState: IAppSpDetail = {
  artId: 0,
  catId: 0,
  spId: 0,
  spDetail: {
    number_of_orders: null,
    imgUrl: '',
    artImgUrl: '',
    class_label: '',
    codeSP: '',
    dateOpenSP: '',
    dateStopSP: '',
    description: '',
    firstPostOldForum: null,
    icoUrl: '',
    ico_label: '',
    countTovarSP: 0,
    dataSize: 0,
    imgSize: 0,
    numArt: 0,
    numSP: 0,
    orgId: 0,
    orgName: '',
    orgPer: 0,
    orgStat: 0,
    orgUrl: '',
    titleSP: '',
    participants: 0,
    unicColor: {},
    unicSize: [],
    unicSex: {},
    filters: {
      color: false,
      material: false,
      model: false,
      sex: false,
      size: false,
      maxPrice: 0,
      minPrice: 0,
    },
  },
  spConditions: '',
  spConditionsLoad: true,
  spDetailLoad: true,
  error: '',
};

export default function (state = initialState, action: IAction) {
  switch (action.type) {
    case SP_DETAIL_ADD_ACTUAL_ART_ID:
      return { ...state, artId: action.payload };
    case SP_DETAIL_ADD_ACTUAL_CAT_ID:
      return { ...state, catId: action.payload };
    case SP_DETAIL_ADD_ACTUAL_SP_ID:
      return { ...state, spId: action.payload };
    case SP_DETAIL_ADD_DATA_SP:
      return { ...state, spDetail: action.payload, spDetailLoad: false };
    case SP_DETAIL_ADD_DATA_SP_CONDITIONS:
      return {
        ...state,
        spConditions: action.payload,
        spConditionsLoad: false,
      };
    //     case SP_ART_LIST_FETCH_SUCCEEDED:
    //       const newSpList = [...state.spArtList, ...action.payload];
    //       return { ...state, spArtList: newSpList, spArtListLoad: false };
    //     case SP_ART_LIST_LOADING_TRUE:
    //       return { ...state, spArtListLoad: true };
    //     case SET_IS_END_SP_TRUE_SP_ART:
    //       return { ...state, isEndSp: true, spArtListLoad: false };
    //     case SET_START_ID_SP_ART:
    //       return { ...state, startIdSp: action.payload };
    //     case SET_START_NUM_SQL_LIST_SP_ART:
    //       return { ...state, startNumSqlList: action.payload };
    //     case SET_COUNT_IN_SQL_LIST_SP_ART:
    //       return { ...state, countInSqlList: action.payload };
    //     case SP_ART_LIST_FETCH_FAILED:
    //       return {
    //         ...state,
    //         error: action.payload,
    //         sectionLoad: false,
    //         catLoad: false,
    //         detCatLoad: false,
    //         spArtListLoad: false,
    //       };
    default:
      return state;
  }
}

import React, { Component } from 'react';
interface IErrorBoundaryState {
  hasError: boolean;
  errorMassenge: string;
}
interface IErrorBoundaryProps {}

export default class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: Readonly<IErrorBoundaryProps>) {
    super(props);
    this.state = {
      hasError: false,
      errorMassenge: ''
    };
  }

  public componentDidCatch(error: any, errorInfo: React.ErrorInfo) {
    this.setState({
      hasError: true,
      errorMassenge: error as string
    });
  }

  public render() {
    if (this.state.hasError) {
      console.log(this.state.hasError);
      return <h1>Что-то пошло не так...</h1>;
    } else {
      return this.props.children;
    }
  }
}

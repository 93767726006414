import {
  SP_DETAIL_ADD_ACTUAL_ART_ID,
  SP_DETAIL_ADD_ACTUAL_CAT_ID,
  SP_DETAIL_ADD_ACTUAL_SP_ID,
  SP_DETAIL_ADD_ACTUAL_IDS,
  SP_DETAIL_ADD_DATA_SP,
  SP_DETAIL_ADD_DATA_SP_CONDITIONS,
} from './actionTypes';
import { ISpDetail, ISpDetailIds } from '../interfaces';

export function ASpDetailAddActualSpId(spId: number) {
  return { type: SP_DETAIL_ADD_ACTUAL_SP_ID, payload: spId };
}

export function ASpDetailAddActualArtId(artId: number) {
  return { type: SP_DETAIL_ADD_ACTUAL_ART_ID, payload: artId };
}

export function ASpDetailAddActualCatId(catId: number) {
  return { type: SP_DETAIL_ADD_ACTUAL_CAT_ID, payload: catId };
}

export function ASpDetailAddActualIds(ids: ISpDetailIds) {
  return { type: SP_DETAIL_ADD_ACTUAL_IDS, payload: ids };
}

export function ASpDetailAddDataSp(data: ISpDetail) {
  return { type: SP_DETAIL_ADD_DATA_SP, payload: data };
}

export function ASpDetailAddDataSpConditions(data: string) {
  return { type: SP_DETAIL_ADD_DATA_SP_CONDITIONS, payload: data };
}

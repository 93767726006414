import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { AppSpWithFilter, AppSpArtsFilter, AppSpDetales } from './App';

import { main, spListArts, spDetail } from './state/sagas';

if (main) {
  ReactDOM.render(
    // <StrictMode>
    <AppSpWithFilter />,
    // </StrictMode>,
    main
  );
}

if (spListArts) {
  ReactDOM.render(
    // <StrictMode>
    <AppSpArtsFilter />,
    // </StrictMode>,
    spListArts
  );
}

if (spDetail) {
  ReactDOM.render(
    // <StrictMode>
    <AppSpDetales />,
    // </StrictMode>,
    spDetail
  );
}

import axios from 'axios';
import isFackeData from './../../../../getDateArea';

let controllerSections = new AbortController();
let controllerCategory = new AbortController();
let controllerDetailCategory = new AbortController();

export const getSections = async () => {
  controllerSections.abort();
  controllerSections = new AbortController();

  if (isFackeData) {
    return getHttpDate(
      '/APIFilterSps/section.json?type=allCat',
      controllerSections.signal
    );
  } else {
    return getHttpDate('/APIFilterSps/?type=allCat', controllerSections.signal);
  }
};

export const getCategory = async (parentCategoryId: number) => {
  controllerCategory.abort();
  controllerCategory = new AbortController();

  if (isFackeData) {
    return getHttpDate(
      `/APIFilterSps/category${parentCategoryId}.json`,
      controllerCategory.signal
    );
  } else {
    return getHttpDate(
      `/APIFilterSps/?type=allCat&c=${parentCategoryId}`,
      controllerCategory.signal
    );
  }
};

export const getDetailCategory = async (parentCategoryId: number) => {
  controllerDetailCategory.abort();
  controllerDetailCategory = new AbortController();

  if (isFackeData) {
    return getHttpDate(
      `/APIFilterSps/detail${parentCategoryId}.json`,
      controllerDetailCategory.signal
    );
  } else {
    return getHttpDate(
      `/APIFilterSps/?type=allCat&c=${parentCategoryId}`,
      controllerDetailCategory.signal
    );
  }
};

async function getHttpDate(strUrl: string, signal?: AbortSignal) {
  const res = await axios.get(strUrl, { signal });
  const { data } = res;
  return data;
}

import { all } from 'redux-saga/effects';
import { categoryfilter } from '../../containers/CategoryFilter/state/sagas';
import { moduleSpListFilter } from '../../modules/AppCategoryFilter/state/sagas';
import { moduleSpArtListFilter } from '../../modules/AppSpArtFilter/state/sagas';
import { moduleSpDetail } from '../../modules/AppSpDetale/state/sagas';

export const main = document.getElementById('mod_om_sp_main');
export const spListArts = document.getElementById('mod_om_sp_list');
export const spDetail = document.getElementById('mod_om_sp_detail');

export default function* rootSaga() {
  if (main) {
    yield all([categoryfilter(), moduleSpListFilter()]);
  } else if (spListArts) {
    yield all([categoryfilter(), moduleSpArtListFilter()]);
  } else if (spDetail) {
    yield all([moduleSpDetail()]);
  }
}

import React, { ReactElement } from 'react';

// interface Props {}

export default function Loader(): ReactElement {
  const color1 = 'rgba(255, 255, 255, 0)';
  const color2 = '#d92819';
  const color3 = '#f8ad04';
  const color4 = '#00761b';
  const color5 = '#4aad0c';

  return (
    <svg
      width="117px"
      height="117px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      style={{
        margin: 'auto',
        background: color1,
        display: 'block',
        shapeRendering: 'auto',
      }}
    >
      <circle cx="84" cy="50" r="0.109646" fill={color2}>
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="0.9615384615384615s"
          calcMode="spline"
          keyTimes="0;1"
          values="10;0"
          keySplines="0 0.5 0.5 1"
          begin="0s"
        ></animate>
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="3.846153846153846s"
          calcMode="discrete"
          keyTimes="0;0.25;0.5;0.75;1"
          values={`${color2};${color5};${color4};${color3};${color2}`}
          begin="0s"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="0" fill={color2}>
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="3.846153846153846s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="3.846153846153846s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="9.89035" fill={color3}>
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="3.846153846153846s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.9615384615384615s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="3.846153846153846s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.9615384615384615s"
        ></animate>
      </circle>
      <circle cx="49.6272" cy="50" r="10" fill={color4}>
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="3.846153846153846s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.923076923076923s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="3.846153846153846s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.923076923076923s"
        ></animate>
      </circle>
      <circle cx="83.6272" cy="50" r="10" fill={color5}>
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="3.846153846153846s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-2.8846153846153846s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="3.846153846153846s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-2.8846153846153846s"
        ></animate>
      </circle>
    </svg>
  );
}

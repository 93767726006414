import { put, takeEvery, call } from 'redux-saga/effects';

import {
  ASpDetailAddActualArtId,
  ASpDetailAddActualSpId,
  ASpDetailAddActualCatId,
  ASpDetailAddDataSp,
  ASpDetailAddDataSpConditions,
} from '../reducer/actions';
import { PRICE_FILTER_UPDATE } from '../../../../containers/PriceFilter/state/reducers/actionTypes';
import { SP_DETAIL_ADD_ACTUAL_IDS } from '../reducer/actionTypes';
import { IAction } from './../../../../interfaces/index';
import {
  ASpArtListFetchRequest,
  ASpArtListReset,
  ASetStartIdSpArt,
  ASetStartNumSQLListSpArt,
} from '../../../AppSpArtFilter/state/reducer/actions';
import { getDataSpDetail, getDataConditionSpDetail } from './API';
import { ISpDetail, ISpDetailIds } from '../interfaces';
import {
  SIZE_FILTER_UPDATE,
  SEX_FILTER_UPDATE,
  TEXT_INPUT_UPDATE,
} from '../../../../containers/DetailFilters/state/reducer/actionTypes';

export function* spDetailUpdate() {
  yield takeEvery(SP_DETAIL_ADD_ACTUAL_IDS, addActualIds);
  yield takeEvery(PRICE_FILTER_UPDATE, updateSpList);
  yield takeEvery(SIZE_FILTER_UPDATE, updateSpList);
  yield takeEvery(SEX_FILTER_UPDATE, updateSpList);
  yield takeEvery(TEXT_INPUT_UPDATE, updateSpList);
}

function* addActualIds(action: IAction) {
  const { artId, catId, spId } = action.payload as ISpDetailIds;

  yield put(ASpDetailAddActualArtId(artId));
  yield put(ASpDetailAddActualSpId(spId));
  yield put(ASpDetailAddActualCatId(catId));
  yield updateSpDetail({ artId, catId, spId });
  yield updateSpList();
}

function* updateSpDetail(sendData: ISpDetailIds) {
  const spDetail: ISpDetail = yield call(getDataSpDetail, sendData);
  const spConditionData: string = yield call(
    getDataConditionSpDetail,
    sendData.spId
  );

  yield put(ASpDetailAddDataSp(spDetail));
  yield put(ASpDetailAddDataSpConditions(spConditionData));
}

function* updateSpList() {
  yield put(ASpArtListReset());
  yield put(ASetStartIdSpArt(0));
  yield put(ASetStartNumSQLListSpArt(0));
  yield put(ASpArtListFetchRequest());
}

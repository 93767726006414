import { IAppPriceFilter, IPriceRange } from '../interfaces';
import { IAction } from '../../../../interfaces';
import {
  PRICE_SELECT,
  SET_STEP,
  SET_MIN,
  SET_MAX,
  SET_MIN_VAL,
  SET_MAX_VAL,
  SET_MORE_THAN_MAX,
} from './actionTypes';

const initialState: IAppPriceFilter = {
  priceMin: 0,
  priceMax: 999999,
  valueMin: 0,
  valueMax: 999999,
  step: 1,
  moreThanMax: false,
};

export default function (state = initialState, action: IAction) {
  switch (action.type) {
    case PRICE_SELECT:
      const { min, max } = action.payload as IPriceRange;
      return {
        ...state,
        valueMin: min,
        valueMax: max,
      };
    case SET_MIN:
      return {
        ...state,
        priceMin: action.payload,
      };
    case SET_MAX:
      return {
        ...state,
        priceMax: action.payload,
      };
    case SET_MIN_VAL:
      return {
        ...state,
        valueMin: action.payload,
      };
    case SET_MAX_VAL:
      return {
        ...state,
        valueMax: action.payload,
      };
    case SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case SET_MORE_THAN_MAX:
      return {
        ...state,
        moreThanMax: action.payload,
      };
    default:
      return state;
  }
}

import React from 'react';
import { connect } from 'react-redux';
import CategoryFilter from '../../containers/CategoryFilter';
import SortMenu from '../../containers/SortMenu/SortMenu';
import SpList from '../../containers/SpList';
import { IRootState } from '../../interfaces';
import { ViewListSP } from './state/interfaces';

interface Props {
  isGreedView?: ViewListSP
}

function AppCategoryFilter({isGreedView = ViewListSP.Greed}: Props) {

  return (
    <div className="row AppCategoryFilter">
      <div className="col-lg-24 col-md-24 col-sm-24 col-xs-24">
        <CategoryFilter />
      </div>
      <div style={{"display": "none"}} className="appSortedFilter">
        <SortMenu />
      </div>
      <div className={`col-lg-24 col-md-24 col-sm-24 col-xs-24 ${isGreedView === ViewListSP.Greed ? 'view-is-greed' : 'view-is-not-greed'}`}>
        <SpList />
      </div>
    </div>
  );
}

function mapStateToProps(state: IRootState) {
  return {
    isGreedView: state.moduleSpListFilter?.isGreedView,
  };
}

export default connect(mapStateToProps)(AppCategoryFilter);

import { put, takeEvery } from 'redux-saga/effects';

import { CATEGORY_FILTER_UPDATE } from '../../../../containers/CategoryFilter/state/reducer/actionTypes';
import {
  ASetStartNumSQLList,
  ASetStartIdSp,
  ASpListReset,
  ASpListFetchRequest,
} from '../reducer/actions';

export function* spListUpdate() {
  yield takeEvery(CATEGORY_FILTER_UPDATE, updateSpList);
}

function* updateSpList() {
  yield put(ASpListReset());
  yield put(ASetStartIdSp(0));
  yield put(ASetStartNumSQLList(0));
  yield put(ASpListFetchRequest());
}

import { put, takeEvery, call } from 'redux-saga/effects';
import {
  SECTION_FETCH_REQUESTED,
  SECTION_FETCH_FAILED,
} from '../reducer/actionTypes';
import { ICategory } from './../interfaces/index';
import { getSections } from './API';
import {
  ASectionFetchSucceeded,
  ASectionLoadingTrue,
} from '../reducer/actions';

export function* sectionFetchRequest() {
  yield takeEvery(SECTION_FETCH_REQUESTED, sectionFetch);
}

function* sectionFetch() {
  yield put(ASectionLoadingTrue());
  try {
    const sections: ICategory[] = yield call(getSections);
    yield put(ASectionFetchSucceeded(sections));
  } catch (e) {
    console.error(e);

    //@ts-ignore
    if (e.message !== 'canceled') {
      //@ts-ignore
      yield put({ type: SECTION_FETCH_FAILED, payload: e.message });
    }
  }
}

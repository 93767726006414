import React from "react";
import { connect } from "react-redux";
import { setUrl, updateSearch } from "../../../common/getURLParam";
import Select from "../../../components/Select";
import { IRootState } from "../../../interfaces";
import { ASelectCategory } from "../state/reducer/actions";

function CategoryInput(props: any) {
  const { categoryes, selectCat, selectCategoryFn, catLoad } = props;
  const defaultValue = "2. ВЫБЕРИТЕ ПОДРАЗДЕЛ";

  const handleClick = (ctegoryId: number) => {
    selectCategoryFn(ctegoryId);

    const searchStr = updateSearch(
      "category",
      ctegoryId > 0 ? `${ctegoryId}` : "Не выбрано"
    );

    setUrl(searchStr);
  };

  return (
    <div
      className={`CategoryInput ${categoryes.length > 1 ? "" : "SectionHide"}`}
    >
      <Select
        optionsList={categoryes}
        selectId={selectCat}
        handleClick={handleClick}
        defaultLabel={defaultValue}
        className="Select"
        disabled={catLoad}
        defaultClickable
      />
    </div>
  );
}

function mapStateToProps(state: IRootState) {
  return {
    categoryes: state.categoryFilter?.categoryes,
    selectCat: state.categoryFilter?.selectCat,
    catLoad: state.categoryFilter?.catLoad,
  };
}

export default connect(mapStateToProps, {
  selectCategoryFn: ASelectCategory,
})(CategoryInput);

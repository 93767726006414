import React, { MouseEvent, memo, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import getURLParams, { urldecode } from '../../common/getURLParam';
import { IRootState } from '../../interfaces';
import CategoryInput from './CategoryInput';
import DetailCategoryInput from './DetailCategoryInput/index';
import SectionInput from './SectionInput';
import {
  AAllReset,
  ACategoruFilterUpdate,
  ACategoryAllReset,
  ACategoryFetchRequest,
  ADetailCategoryAllReset,
  ADetailCategoryFetchRequest,
  ADetailCategoryFetchSucceeded,
  ASectionAllReset,
  ASectionFetchRequest,
  ASelectCategory,
  ASelectDetailCategory,
  ASelectSection,
} from './state/reducer/actions';
import './style.scss';
import TextSearchInput from './TextSearchInput/TextSearchInput';

function CategoryFilter(props: any) {
  const didMountRef = useRef(false);
  const {
    selectSection,
    selectCat,
    selectDetCat,
    textSearch,
    sectionFetchrequest,
    selectSectionFn,
    categoryAllResetFn,
    categoryFetchRequest,
    selectCategoryFn,
    sectionAllResetFn,
    detailCategoryFetchRequest,
    detailCategoryAllResetFn,
    selectDetailCategoryFn,
    resetListDetailcat,
    sendUpdateCategoryFilterAction,
    isTextFilter = false,
    allReset,
  }: {
    selectSection: number;
    selectCat: number;
    textSearch: string;
    selectDetCat: number;
    sectionFetchrequest: Function;
    selectSectionFn: Function;
    detailCategoryAllResetFn: Function;
    allReset: Function;
    categoryAllResetFn: Function;
    sectionAllResetFn: Function;
    categoryFetchRequest: Function;
    selectCategoryFn: Function;
    detailCategoryFetchRequest: Function;
    selectDetailCategoryFn: Function;
    resetListDetailcat: Function;
    sendUpdateCategoryFilterAction: Function;
    isTextFilter?: boolean;
  } = props;

  const updateSpList = useCallback(() => {
    sendUpdateCategoryFilterAction();
  }, [sendUpdateCategoryFilterAction]);

  const handleResetFilters = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    allReset();
  };

  // component did mount
  useEffect(() => {
    const sectionId = parseInt(urldecode(getURLParams('section')).trim());
    const ctegoryId = parseInt(urldecode(getURLParams('category')).trim());
    const detCtegoryId = parseInt(urldecode(getURLParams('dcategory')).trim());

    sectionFetchrequest();
    if (!isNaN(sectionId) && sectionId > 0) {
      selectSectionFn(sectionId);
      categoryFetchRequest(sectionId);

      if (!isNaN(ctegoryId) && ctegoryId > 0) {
        selectCategoryFn(ctegoryId);
        detailCategoryFetchRequest(ctegoryId);

        if (!isNaN(detCtegoryId) && detCtegoryId > 0) {
          selectDetailCategoryFn(detCtegoryId);
        }
      } else {
        updateSpList();
        console.log(0);
      }
    } else {
      didMountRef.current = true;
      updateSpList();
      console.log(0);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // component did Update
  useEffect(() => {
    if (didMountRef.current) {
      if (selectSection > 0) {
        selectCategoryFn(0);
        categoryFetchRequest(selectSection);
        // selectDetailCategoryFn(0);
        resetListDetailcat();
        updateSpList();
        // console.log(1);
      } else {
        sectionAllResetFn();
        updateSpList();
        // console.log(2);
      }
    }
    if (!didMountRef.current && selectSection > 0) {
      didMountRef.current = true;
    }
  }, [
    categoryFetchRequest,
    selectCategoryFn,
    selectDetailCategoryFn,
    resetListDetailcat,
    updateSpList,
    selectSection,
  ]);

  useEffect(() => {
    if (didMountRef.current) {
      if (selectCat > 0) {
        // selectDetailCategoryFn(0);
        detailCategoryFetchRequest(selectCat);
        updateSpList();
        // console.log(3);
      } else {
        categoryAllResetFn();
        updateSpList();
        // console.log(4);
      }
    }
  }, [
    selectDetailCategoryFn,
    detailCategoryFetchRequest,
    updateSpList,
    selectCat,
  ]);

  useEffect(() => {
    if (didMountRef.current) {
      if (selectDetCat > 0) {
        updateSpList();
        console.log(5);
      } else {
        detailCategoryAllResetFn();
        updateSpList();
        console.log(6);
      }
    }
  }, [updateSpList, selectDetCat]);

  useEffect(() => {
    if (didMountRef.current) {
      updateSpList();
      console.log(7);
    }
  }, [updateSpList, textSearch]);

  return (
    <>
      {isTextFilter && (
        <div className="Filter">
          <TextSearchInput />{' '}
        </div>
      )}
      <div className="Filter">
        <div className="FirstFilterWrapper">
          <div style={{ display: 'none' }} className="FilterIconContainer">
            <div className="FilterIcon">
              <i className="icon-loupe" />
              <span>Поиск по каталогу</span>
            </div>
            <div className="FilterChevron"></div>
          </div>
          <SectionInput />
        </div>
        <CategoryInput />
        <div className="lastFilterWrapper">
          <DetailCategoryInput />
          <div style={{ display: 'none' }} className="ResetButtonContainer">
            {selectSection > 0 ? (
              <a onClick={handleResetFilters}>
                <i className="icon-reset" />
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state: IRootState) {
  return {
    selectSection: state.categoryFilter?.selectSection,
    selectCat: state.categoryFilter?.selectCat,
    selectDetCat: state.categoryFilter?.selectDetCat,
    textSearch: state.categoryFilter?.textSearch,
  };
}

export default memo(
  connect(mapStateToProps, {
    sectionFetchrequest: ASectionFetchRequest,
    selectSectionFn: ASelectSection,
    sectionAllResetFn: ASectionAllReset,
    categoryAllResetFn: ACategoryAllReset,
    detailCategoryAllResetFn: ADetailCategoryAllReset,
    categoryFetchRequest: ACategoryFetchRequest,
    selectCategoryFn: ASelectCategory,
    detailCategoryFetchRequest: ADetailCategoryFetchRequest,
    selectDetailCategoryFn: ASelectDetailCategory,
    sendUpdateCategoryFilterAction: ACategoruFilterUpdate,
    resetListDetailcat: () => ADetailCategoryFetchSucceeded([]),
    allReset: AAllReset,
  })(CategoryFilter)
);

import { combineReducers } from 'redux';

import moduleSpListFilter from '../../modules/AppCategoryFilter/state/reducer';
import categoryFilter from '../../containers/CategoryFilter/state/reducer';
import priceFilter from '../../containers/PriceFilter/state/reducers';
import moduleSpArtListFilter from '../../modules/AppSpArtFilter/state/reducer';
import moduleSpDetail from '../../modules/AppSpDetale/state/reducer';
import detailFilter from '../../containers/DetailFilters/state/reducer';

export default combineReducers({
  moduleSpListFilter,
  categoryFilter,
  priceFilter,
  detailFilter,
  moduleSpArtListFilter,
  moduleSpDetail,
});

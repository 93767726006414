import axios from 'axios';

import isFackeData from '../../../../getDateArea';
import { ISpDetailIds, IFetchSpDetalArtListData } from '../interfaces';

let controllerSpDetail = new AbortController();
let controllerConditionSpDetail = new AbortController();
let controllerSpArtList = new AbortController();

export const getDataSpDetail = async ({ spId, artId, catId }: ISpDetailIds) => {
  let strUrl = '';
  controllerSpDetail.abort();
  controllerSpDetail = new AbortController();

  if (isFackeData) {
    strUrl = `/APIFilterArts/artDetail.json?type=getSp&sp=${spId}&art=${artId}`;
  } else {
    strUrl = `/APIFilterArts/?type=getSp&sp=${spId}&art=${artId}`;
  }

  return getHttpDate(strUrl, controllerSpDetail.signal);
};

export const getDataConditionSpDetail = async (spId: number) => {
  let strUrl = '';
  controllerConditionSpDetail.abort();
  controllerConditionSpDetail = new AbortController();

  if (isFackeData) {
    strUrl = `/APIFilterArts/spConditions.html?type=getSp&sp=${spId}`;
  } else {
    strUrl = `/APIFilterArts/?type=getSpConditions&sp=${spId}`;
  }

  return getHttpDate(strUrl, controllerConditionSpDetail.signal);
};

export const getSpArtList = async (sendData: IFetchSpDetalArtListData) => {
  controllerSpArtList.abort();
  controllerSpArtList = new AbortController();
  const {
    artId,
    catId,
    color,
    spId,
    startStep,
    stc,
    step,
    valueMax,
    valueMin,
    selectSex,
    inputText,
    selectSize,
  } = sendData;
  let strUrl = '';

  if (isFackeData) {
    strUrl = `/APIFilterArts/spArtlist.json?type=filterBySp&stc=${stc}&sp=${spId}&art=${artId}&size=${selectSize}&sex=${selectSex}&color=${color}&minp=${valueMin}&maxp=${valueMax}&cat=${catId}&s=${startStep}&n=${step}&txt=${inputText}`;
  } else {
    strUrl = `/APIFilterArts/?type=filterBySp&stc=${stc}&sp=${spId}&art=${artId}&size=${selectSize}&sex=${selectSex}&color=${color}&minp=${valueMin}&maxp=${valueMax}&cat=${catId}&s=${startStep}&n=${step}&txt=${inputText}`;
  }

  return getHttpDate(strUrl, controllerSpArtList.signal);
};

// export const getSpArtList = async (dataSend: IFetchSpArtListData) => {
//   const {
//     startNumSqlList,
//     countInSqlList,
//     startIdSp,
//     section,
//     category,
//     categoryDetal,
//     typePromo,
//     valueMin,
//     valueMax,
//     ramgeMin,
//     ramgeMax,
//     moreThanMax,
//   } = dataSend;
//   let sentCatId = '';

//   if (categoryDetal) {
//     sentCatId = `&cd=${categoryDetal}`;
//   } else if (category) {
//     sentCatId = `&c=${category}`;
//   } else if (section) {
//     sentCatId = `&sc=${section}`;
//   }

//   let sentTypePromo = '';
//   if (typePromo) {
//     sentTypePromo = `&typePromo=${typePromo}`;
//   }

//   let sentMinMaxPrice = '';
//   if (valueMax !== -1 || valueMin !== -1) {
//     let min = valueMin;
//     let max = valueMax;
//     if (valueMin === -1) {
//       //если не определено, то равно дефолтному значению
//       min = ramgeMin;
//     }
//     if (valueMax === -1) {
//       //если не определено, то равно дефолтному значению
//       max = ramgeMax;

//       if (moreThanMax) {
//         max = 999999;
//       }
//     }
//     sentMinMaxPrice = `&minp=${min}&maxp=${max}`;
//   }

//   let strUrl = '';

//   if (isFackeData) {
//     strUrl = `/APIFilterArts/spArtlist.json?type=filter${sentCatId}&idSp=${startIdSp}${sentTypePromo}&s=${startNumSqlList}&n=${countInSqlList}${sentMinMaxPrice}`;
//   } else {
//     strUrl = `/APIFilterArts/?type=filter${sentCatId}&idSp=${startIdSp}${sentTypePromo}&s=${startNumSqlList}&n=${countInSqlList}${sentMinMaxPrice}`;
//   }

//   if (
//     categoryDetal === 0 &&
//     category === 0 &&
//     section === 0 &&
//     valueMin === -1 &&
//     valueMax === -1 &&
//     !typePromo
//   ) {
//     if (isFackeData) {
//       strUrl = `/APIFilterArts/spArtlist.json?s=${startNumSqlList}&n=${countInSqlList}&idSp=${startIdSp}`;
//     } else {
//       strUrl = `/APIFilterArts/?s=${startNumSqlList}&n=${countInSqlList}&idSp=${startIdSp}`;
//     }
//   }

//   return getHttpDate(strUrl);
// };

async function getHttpDate(strUrl: string, signal?: AbortSignal) {
  const res = await axios.get(strUrl, {
    signal,
  });
  const { data } = res;
  return data;
}

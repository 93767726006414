import { useEffect } from 'react';

import getURLParams from '../../../common/getURLParam';
import { ISpDetailIds } from '../state/interfaces';

export const useInitialState = (
  spDetailAddActualIds?: (ids: ISpDetailIds) => void
) => {
  useEffect(() => {
    const sp = (getURLParams('sp') as unknown) as number;
    const art = (getURLParams('art') as unknown) as number;
    const cat = (getURLParams('cat') as unknown) as number;

    let spId = 0;
    let artId = 0;
    let catId = 0;

    if (sp > 0) spId = sp;
    if (art > 0) artId = art;
    if (cat > 0) catId = cat;

    spDetailAddActualIds!({ spId, artId, catId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

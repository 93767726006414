import React, { useState, useRef, useEffect, FC } from 'react';

import './style.scss';

interface Props {
  label?: string;
  items: string[];
  defaultValue?: string[];
  onChangeItem: (name: string, val: boolean) => void;
}

const MultiSelect: FC<Props> = ({
  label = 'Всего',
  items,
  defaultValue = [],
  onChangeItem,
}) => {
  const wrapperRef = useRef(null);
  const [count, setCount] = useState(defaultValue.length);
  const [checkeds, setChecked] = useState<string[]>(defaultValue);
  const { isOpen, togOpen } = useOutsideAlerter(wrapperRef);

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    togOpen(!isOpen);
  };

  const handleOnClickListEl = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    e.stopPropagation();
  };

  const handleOnChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (e.currentTarget.checked) {
      setCount(count + 1);
      setChecked([...checkeds, e.currentTarget.name]);
    } else {
      const newA = checkeds.filter((el) => el !== e.currentTarget.name);
      setChecked(newA);
      setCount(count - 1);
    }
    onChangeItem(e.currentTarget.name, e.currentTarget.checked);
  };

  return (
    <div
      onClick={handleOnClick}
      className="MultiSelect__container"
      ref={wrapperRef}
    >
      <div className="MultiSelect__fild">
        <span>
          {label}: {count} элемент(а/ов) выбрано
        </span>
        <div className="MultiSelect__arrayContainer">
          <i className={`arrow ${isOpen ? 'up' : 'down'}`}></i>
        </div>
      </div>
      {isOpen && (
        <div className="MultiSelect__option_list">
          {items &&
            items.map((name) => {
              const isSelect = checkeds.find((el) => el === name);
              return (
                <div
                  onClick={handleOnClickListEl}
                  className={`MultiSelect__option_item ${
                    isSelect ? 'selected' : ''
                  }`}
                  key={name}
                >
                  <label>
                    <input
                      type="checkbox"
                      checked={isSelect ? true : false}
                      onChange={handleOnChangeCheckBox}
                      name={name}
                    />
                    <span>{name}</span>
                  </label>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;

//---------------------------------------------------

function useOutsideAlerter(ref: any) {
  const [isOpen, togOpen] = useState(false);

  function handleClickOutside(e: MouseEvent) {
    if (ref.current && !ref.current.contains(e.target)) {
      if (isOpen) {
        togOpen(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return { isOpen, togOpen };
}
